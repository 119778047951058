<template>
  <section class="GeneralPage">
    <div 
      v-if="loadingWizard" 
      class="loader"/>
    <div 
      class="container" 
      style="margin-bottom: 48px;">
      <legend>Sell/Trade-In</legend>
      <hr>
      <form-wizard
        title
        shape="tab"
        subtitle="Please complete the steps below."
        finish-button-text="Submit"
        color="#0232A1"
        error-color="#e55e5a"
        @on-complete="onComplete"
        @on-loading="setLoading">
        <tab-content 
          :before-change="()=>validateStep('step1')" 
          title="Vehicle Details">
          <div class="gForm gForm--SftBg">
            <legend>Your Vehicle Details</legend>
            <hr>
            <div 
              :class="errors.has('step1.reg_year')?'has-danger':''" 
              class="form-group">
              <Select2
                v-validate="'required'"
                v-model="form.reg_year"
                :settings="{'width':'100%','placeholder':'Select Year'}"
                :options="years"
                data-vv-as="Registration Year"
                data-vv-scope="step1"
                name="reg_year"
                @change="getVehicleTypes"/>
              <div
                v-show="errors.has('step1.reg_year')"
                class="help-block">{{ errors.first('step1.reg_year') }}</div>
            </div>
            <div 
              :class="errors.has('step1.selected_type')?'has-error':''" 
              class="form-group">
              <Select2
                v-validate="'required'"
                v-model="form.selected_type"
                :settings="{'width':'100%','placeholder':'Select Vehicle Type'}"
                :options="vehicle_types"
                data-vv-as="Vehicle Type"
                data-vv-scope="step1"
                name="selected_type"
                @change="getMake"/>
              <span
                v-show="errors.has('step1.selected_type')"
                class="help-block">{{ errors.first('step1.selected_type') }}</span>
            </div>
            <div 
              :class="errors.has('step1.selected_make')?'has-error':''" 
              class="form-group">
              <Select2
                v-validate="'required'"
                v-model="form.selected_make"
                :settings="{'width':'100%','placeholder':'Select Make'}"
                :options="vehicle_makes"
                data-vv-as="Vehicle Make"
                data-vv-scope="step1"
                name="selected_make"
                @change="getModel"/>
              <span
                v-show="errors.has('step1.selected_make')"
                class="help-block">{{ errors.first('step1.selected_make') }}</span>
            </div>
            <div 
              :class="errors.has('step1.selected_model')?'has-error':''" 
              class="form-group">
              <Select2
                v-validate="'required'"
                v-model="form.selected_model"
                :settings="{'width':'100%','placeholder':'Select Model'}"
                :options="vehicle_models"
                data-vv-as="Vehicle Model"
                data-vv-scope="step1"
                name="selected_model"
                @change="getModelType"/>
              <span
                v-show="errors.has('step1.selected_model')"
                class="help-block">{{ errors.first('step1.selected_model') }}</span>
            </div>
            <div 
              :class="errors.has('step1.selected_model_type')?'has-error':''" 
              class="form-group">
              <Select2
                v-validate="'required'"
                v-model="form.selected_model_type"
                :settings="{'width':'100%','placeholder':'Select Model Type'}"
                :options="vehicle_model_types"
                data-vv-as="Model Types"
                data-vv-scope="step1"
                name="selected_model_type"
                @select="selectedModelType($event)"/>
              <span
                v-show="errors.has('step1.selected_model_type')"
                class="help-block">{{ errors.first('step1.selected_model_type') }}</span>
            </div>
            <div 
              :class="errors.has('step1.mileage')?'has-error':''" 
              class="form-group">
              <input
                v-validate="'required'"
                v-model.trim="form.mileage"
                class="form-control"
                placeholder="Enter Vehicle Mileage"
                data-vv-as="Mileage"
                data-vv-scope="step1"
                name="mileage"
                type="number">
              <span
                v-show="errors.has('step1.mileage')"
                class="help-block">{{ errors.first('step1.mileage') }}</span>
            </div>
          </div>
        </tab-content>
        <tab-content 
          :before-change="()=>validateStep('step2')" 
          title="My Details">
          <div class="gForm gForm--SftBg">
            <legend>Add your details</legend>
            <hr>
            <div 
              :class="errors.has('step2.first_name')?'has-error':''" 
              class="form-group">
              <input
                v-validate="'required'"
                v-model="form.first_name"
                data-vv-as="First Name"
                data-vv-scope="step2"
                name="first_name"
                type="text"
                placeholder="Enter First Name"
                class="form-control input-lg">
              <span
                v-show="errors.has('step2.first_name')"
                class="help-block">{{ errors.first('step2.first_name') }}</span>
            </div>
            <div 
              :class="errors.has('step2.last_name')?'has-error':''" 
              class="form-group">
              <input
                v-validate="'required'"
                v-model="form.last_name"
                data-vv-as="Last Name"
                data-vv-scope="step2"
                name="last_name"
                type="text"
                placeholder="Enter Last Name"
                class="form-control input-lg">
              <span
                v-show="errors.has('step2.last_name')"
                class="help-block">{{ errors.first('step2.last_name') }}</span>
            </div>
            <div 
              :class="errors.has('step2.email_address')?'has-error':''" 
              class="form-group">
              <input
                v-validate="'required|email'"
                v-model="form.email_address"
                data-vv-as="Email Address"
                data-vv-scope="step2"
                name="email_address"
                type="text"
                placeholder="Enter Email Address"
                class="form-control input-lg">
              <span
                v-show="errors.has('step2.email_address')"
                class="help-block">{{ errors.first('step2.email_address') }}</span>
            </div>
            <div 
              :class="errors.has('step2.cell_no')?'has-error':''" 
              class="form-group">
              <input
                v-validate="{ rules: { required: true, numeric: true, regex:/^0[687][0123456789]((\d{7})|( |-)((\d{3}))( |-)(\d{4})|( |-)(\d{7}))$/} }"
                v-model="form.cell_no"
                data-vv-as="Cellphone Number"
                data-vv-scope="step2"
                name="cell_no"
                type="number"
                placeholder="Enter Cellphone Number"
                class="form-control input-lg">
              <span
                v-show="errors.has('step2.cell_no')"
                class="help-block">{{ errors.first('step2.cell_no') }}</span>
            </div>
            <div 
              :class="errors.has('step2.province')?'has-error':''" 
              class="form-group">
              <select
                v-validate="'required'"
                v-model="form.province"
                data-vv-as="Province"
                data-vv-scope="step2"
                name="province"
                class="form-control input-lg">
                <option 
                  value 
                  disabled 
                  hidden>Select Province</option>
                <option value="BOT">Botswana</option>
                <option value="EC">Eastern Cape</option>
                <option value="FS">Freestate</option>
                <option value="GT">Gauteng</option>
                <option value="KZN">KwaZulu-Natal</option>
                <option value="LMP">Limpopo</option>
                <option value="MP">Mpumalanga</option>
                <option value="NAM">Namibia</option>
                <option value="NC">Northern Cape</option>
                <option value="NW">North West</option>
                <option value="SWA">Swaziland</option>
                <option value="WC">Western Cape</option>
              </select>
              <span
                v-show="errors.has('step2.province')"
                class="help-block">{{ errors.first('step2.province') }}</span>
            </div>
            <div 
              :class="errors.has('step2.requested_amount')?'has-error':''" 
              class="form-group">
              <input
                v-validate="'required|numeric'"
                v-model.trim="form.requested_amount"
                data-vv-as="Requested Amount"
                data-vv-scope="step2"
                name="requested_amount"
                type="number"
                placeholder="How much would you like for your vehicle?"
                class="form-control input-lg">
              <span
                v-show="errors.has('step2.requested_amount')"
                class="help-block">{{ errors.first('step2.requested_amount') }}</span>
            </div>
          </div>
        </tab-content>
        <tab-content 
          :before-change="()=>validateStep('step3')" 
          title="Photos" 
          class="text-center">
          <h6>Vehicle images</h6>
          <p>Please upload clear images of your vehicle.</p>
          <hr>
          <div v-if="files.length">
            <li 
              v-for="file in files" 
              :key="file.id">
              <span>{{ file.name }}</span>
              <span v-if="file.error">
                <br>
                {{ file.error }}
              </span>
              <span v-else-if="file.success">
                <div 
                  class="alert alert-success" 
                  role="alert">{{ uploadMsg }}</div>
              </span>
              <span v-else-if="file.active">active</span>
              <span v-else-if="file.active">active</span>
              <span v-else/>
            </li>
          </div>
          <div v-else>
            <div class="text-center p-5">
              <h4>
                Drop files anywhere to upload
                <br>or
              </h4>
            </div>
          </div>

          <div 
            v-show="$refs.upload && $refs.upload.dropActive" 
            class="drop-active">
            <h3>Drop files to upload</h3>
          </div>
          <div 
            :class="errors.has('step3.image_upload')?'has-error':''" 
            class="form-group">
            <file-upload
              v-validate="'required'"
              ref="upload"
              v-model="files"
              :multiple="true"
              :drop="true"
              :drop-directory="true"
              :custom-action="uploadFile"
              data-vv-as="Vehicle Image(s)"
              data-vv-scope="step3"
              name="image_upload"
              extensions="gif,jpg,jpeg,png,webp"
              accept="image/png, image/gif, image/jpeg, image/webp"
              class="btn btn-primary"
              @input-filter="inputFilter">
              <img 
                src="@/assets/img/upload.png" 
                style="width:15%;" >
            </file-upload>
            <br>
            <span
              v-show="errors.has('step3.image_upload')"
              class="help-block">{{ errors.first('step3.image_upload') }}</span>
          </div>
        </tab-content>
        <button 
          slot="prev" 
          class="btn btn-primary col-sm-6 float-left">Prev</button>
        <button 
          slot="next" 
          class="btn btn-primary col-sm-6 float-right">Next</button>
        <button 
          slot="finish" 
          class="btn btn-primary col-sm-6 float-right">Submit</button>
      </form-wizard>
    </div>
  </section>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import swal from "sweetalert2";
import Select2 from "v-select2-component";
import FileUpload from "vue-upload-component";
import ImageCompressor from "image-compressor.js";

export default {
  name: "SellMyVehicle",
  components: {
    Select2,
    FormWizard,
    FileUpload,
    TabContent
  },
  data() {
    return {
      loadingWizard: false,
      vehicle_types: [],
      vehicle_makes: [],
      vehicle_models: [],
      vehicle_model_types: [],
      files: [],
      autoCompress: 300 * 300,
      form: {
        reg_year: null,
        selected_type: null,
        selected_make: null,
        selected_model: null,
        selected_model_type: null,
        selected_model_type_name: null,
        mileage: null,
        first_name: null,
        last_name: null,
        cell_no: null,
        email_address: null,
        province: "",
        requested_amount: null,
        storeReference: []
      },
      uploadMsg: ""
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1980 },
        (value, index) => 1981 + index
      );
    }
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    selectedModelType(event) {
      this.form.selected_model_type_name = event.text;
    },
    getVehicleTypes() {
      this.setLoading(true);
      this.$http
        .post("vehicle/types")
        .then(response => {
          this.setLoading(false);
          if (response.data) this.vehicle_types = response.data;
        })
        .catch(() => {
          this.setLoading(false);
          return Promise.resolve();
      });
    },
    getMake() {
      const data = {
        vehicle_type: this.form.selected_type,
        reg_year: this.form.reg_year
      };
      this.setLoading(true);
      this.$http
        .post("vehicle/makes", data)
        .then(response => {
          this.setLoading(false);
          if (response.data) this.vehicle_makes = response.data;
        })
        .catch(() => {
          this.setLoading(false);
          return Promise.resolve();
      });
    },
    getModel() {
      const data = {
        vehicle_type: this.form.selected_type,
        reg_year: this.form.reg_year,
        make: this.form.selected_make
      };
      this.setLoading(true);
      this.$http
        .post("vehicle/models", data)
        .then(response => {
          this.setLoading(false);
          if (response.data) this.vehicle_models = response.data;
        })
        .catch(() => {
          this.setLoading(false);
          return Promise.resolve();
      });
    },
    getModelType() {
      const data = {
        vehicle_type: this.form.selected_type,
        reg_year: this.form.reg_year,
        make: this.form.selected_make,
        model: this.form.selected_model
      };
      this.setLoading(true);
      this.$http
        .post("vehicle/modelTypes", data)
        .then(response => {
          this.setLoading(false);
          if (response.data) this.vehicle_model_types = response.data;
        })
        .catch(() => {
          this.setLoading(false);
          return Promise.resolve();
      });
    },
    validateStep(scope) {
      return new Promise(resolve => {
        this.$validator
          .validateAll(scope)
          .then(isValid => {
            if (scope === "step3" && this.form.storeReference.length === 0) {
              resolve(false);
            } else {
              resolve(isValid);
            }
          })
          .catch(() => {
            resolve(false);
        });
      });
    },
    onComplete: function() {
      this.setLoading(true);
      this.$http
        .post("lead/save", this.form)
        .then(response => {
          this.setLoading(false);
          if (response) {
            swal(
              "Success!",
              "Your vehicle has been submitted. A representitive will be in contact shortly.",
              "success"
            );
            this.$router.push("/");
          }
        })
        .catch(() => {
          this.setLoading(false);
          return Promise.resolve(true);
      });
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent();
        }

        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }

        if (
          newFile.file &&
          newFile.type.substr(0, 6) === "image/" &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = "Compressing";
          const imageCompressor = new ImageCompressor(null, {
            convertSize: 2000000,
            maxWidth: 200,
            maxHeight: 200,
            quality: 0.1
          });
          imageCompressor
            .compress(newFile.file)
            .then(file => {
              this.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type
              });
            })
            .catch(err => {
              this.$refs.upload.update(newFile, {
                error: err.message || "Compress"
              });
          });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    async uploadFile(file) {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file.file);
      fileReader.onload = e => {
        const data = {
          image_64: e.target.result
        };
        this.uploadMsg = "Uploading";
        this.$http
          .post("lead/ImageStore", data)
          .then(response => {
            this.uploadMsg = "Uploaded";
            this.form.storeReference.push(response.data.data.ref);
            return Promise.resolve(true);
          })
          .catch(error => {
            return Promise.reject(error);
        });
      };
    }
  }
};
</script>

<style scoped>
span.error {
  color: #e74c3c;
  font-size: 20px;
  display: flex;
  justify-content: center;
}
</style>
