<template>
  <div
    :id="'buyModal'+componentId"
    class="modal show modalman"
    role="dialog">
    <!-- Modal content-->
    <div class="modal-dialog test">
      <!-- Modal content-->
      <div class="modal-content">
        <!-- One "tab" for each step in the form: -->
        <div
          class="tab modal-tab">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal">&times;</button>
          </div>
          <h1 class="modal_head">Congratulations!</h1>
          <div class="modal_text">
            You have selected to purchase this vehicle!
            <br >Please select your choice of purchase to continue to the next step.
          </div>
          <div class="container">
            <div
              class="row"
              style="margin-bottom: 48px">
              <div class="col-lg-5 img-position">
                <img
                  class="modal_img"
                  src="@/assets/img/modalhand.png"
                  alt >
              </div>

              <div class="col-lg-2">
                <img
                  class
                  src="@/assets/img/Group_2809.png"
                  alt >
              </div>

              <div class="col-lg-5 img-position">
                <img
                  class="modal_img"
                  src="@/assets/img/modalcard.png"
                  alt >
              </div>
            </div>

            <div class="row select_payment">
              <div class="col-lg-6 select_btn">
                <button
                  id="Cash_Btn"
                  type="button"
                  @click="nextPrev(1)">CASH PURCHASE</button>
              </div>
              <div class="col-lg-6 select_btn">
                <button
                  id="Fin_Btn"
                  type="button"
                  @click="nextPrev(3)">FINANCE PURCHASE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cash_price">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <form
            id="regForm"
            action>
            <!-- One "tab" for each step in the form: -->
            <div class="tab modal-tab">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal">&times;</button>
              </div>
              <h1 class="modal_head">Additional Products</h1>
              <div class="container">
                <div
                  class="row"
                  style="margin-bottom: 48px">
                  <div class="col-lg-12">
                    <table class="table_2">
                      <tr
                        v-for="(product, index) in products"
                        :key="index"
                        class="underline_tr">
                        <td class="td_2">{{ product.display_name }}</td>
                        <td class="td_3">{{ product.financial_amount_display }}</td>
                        <td class="td_2">
                          <div class="custom-control_new custom-checkbox">
                            <input
                              :disabled="product.mandatory > 0"
                              v-model="product.checked"
                              :id="componentId+product.id"
                              type="checkbox"
                              class="custom-control-input" >
                            <label
                              :for="componentId+product.id"
                              class="custom-control-label" />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="nextPrev(-1)">BACK</button>
                  </div>
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="nextPrev(1)">NEXT STEP</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab modal-tab">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal">&times;</button>
              </div>
              <h1 class="modal_head">Almost there!</h1>
              <div class="modal_text">
                You have selected a cash deal on a {{ vehicle.display_name }}
                with the following:
              </div>
              <div class="container">
                <div
                  class="row"
                  style="margin-bottom: 48px">
                  <div class="col-lg-12">
                    <table class="table_2">
                      <tr class="underline_tr">
                        <td class="td_2">{{ vehicle.display_name }}</td>
                        <td class="td_31 float-right">{{ vehicle.buy_now_price }}</td>
                      </tr>

                      <tr
                        v-for="(selectedProduct, index) in selectedProducts"
                        :key="index"
                        class="underline_tr">
                        <td class="td_2">{{ selectedProduct.display_name }}</td>
                        <td class="td_31 float-right">{{ selectedProduct.financial_amount_display }}</td>
                      </tr>
                    </table>

                    <table class="table_2">
                      <tr class="underline_tr">
                        <td class="td_4">
                          Your total vehicle price is:
                          <span class="td_price">
                            {{ totalBilledValue }}
                          </span>
                        </td>
                      </tr>

                      <tr class>
                        <td class="td_4">
                          <span>I accept the <router-link
                            target="_blank"
                            to="/terms#buy"> Terms and Conditions</router-link></span>
                          <div class="custom-control_new custom-checkbox">
                            <input
                              v-validate="'required'"
                              :id="'cashTerms'+componentId"
                              v-model="terms"
                              data-vv-scope="cash"
                              name="terms"
                              data-vv-as="Terms and Conditions"
                              type="checkbox"
                              class="custom-control-input" >
                            <label
                              :for="'cashTerms'+componentId"
                              class="custom-control-label" />
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div
                      v-show="errors.has('cash.terms')"
                      class="alert alert-danger">
                      {{ errors.first("cash.terms") }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="nextPrev(-1)">BACK</button>
                  </div>
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="submit('cash')">SUBMIT</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Circles which indicates the steps of the form: -->
            <div style="text-align:center;margin-top:40px;margin-bottom: 48px">
              <span class="step" />
              <span class="step" />
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="finance_price">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <form
            id="regForm"
            action>
            <!-- One "tab" for each step in the form: -->

            <div class="tab modal-tab">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal">&times;</button>
              </div>
              <h1 class="modal_head">Additional Products</h1>

              <div class="container">
                <div
                  class="row"
                  style="margin-bottom: 48px">
                  <div class="col-lg-12">
                    <table class="table_2">
                      <tr
                        v-for="(product, index) in products"
                        :key="index"
                        class="underline_tr">
                        <td class="td_2">{{ product.display_name }}</td>
                        <td class="td_3">{{ product.financial_amount_display }}</td>
                        <td class="td_2">
                          <div class="custom-control_new custom-checkbox">
                            <input
                              :disabled="product.mandatory > 0"
                              v-model="product.checked"
                              :id="componentId+product.id"
                              type="checkbox"
                              class="custom-control-input" >
                            <label
                              :for="componentId+product.id"
                              class="custom-control-label" />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="nextPrev(-3)">BACK</button>
                  </div>
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="nextPrev(1)">NEXT STEP</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab modal-tab">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal">&times;</button>
              </div>
              <h1 class="modal_head">Almost there!</h1>

              <div class="modal_text">
                You have selected a cash deal on a {{ vehicle.display_name }}
                with the following:
              </div>

              <div class="container">
                <div
                  class="row"
                  style="margin-bottom: 48px">
                  <div class="col-lg-12">
                    <table class="table_2">
                      <tr class="underline_tr">
                        <td class="td_2">{{ vehicle.display_name }}</td>
                        <td class="td_31 float-right">{{ vehicle.buy_now_price }}</td>
                      </tr>
                      <tr
                        v-for="(selectedProduct, index) in selectedProducts"
                        :key="index"
                        class="underline_tr">
                        <td class="td_2">{{ selectedProduct.display_name }}</td>
                        <td class="td_31 float-right">{{ selectedProduct.financial_amount_display }}</td>
                      </tr>
                    </table>

                    <table class="table_2">
                      <tr class="underline_tr">
                        <td class="td_4">
                          Your total vehicle price is:
                          <span class="td_price">
                            {{ totalBilledValue }}
                          </span>
                        </td>
                      </tr>

                      <tr class>
                        <td class="td_4">
                          <span>I accept the <router-link
                            target="_blank"
                            to="/terms#buy"> Terms and Conditions</router-link></span>
                          <div class="custom-control_new custom-checkbox">
                            <input
                              v-validate="'required'"
                              v-model="terms"
                              :id="'financeTerms'+componentId"
                              name="terms"
                              data-vv-as="Terms and Conditions"
                              type="checkbox"
                              class="custom-control-input" >
                            <label
                              :for="'financeTerms'+componentId"
                              class="custom-control-label" />
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div
                      v-show="errors.has('terms')"
                      class="alert alert-danger">
                      {{ errors.first("terms") }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="nextPrev(-1)">BACK</button>
                  </div>
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="toFinalStep()">NEXT STEP</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab modal-tab">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal">&times;</button>
              </div>
              <h1 class="modal_head">Final Step</h1>

              <div class="modal_text">
                You will now be redirected to the Standard Bank finance application website
                where you will receive an
                <b>reference number</b>. Please use the total vehicle deal
                price when completing your application. This process is merely a finance application, the total
                amount financed can be amended accordingly should you wish to make use of a deposit. Our
                agents will be in contact after you have completed your application to discuss your
                options.
              </div>

              <div class="modal_text">
                <span style="color: #0089FF; font-weight: 600">
                  Please
                  Note:
                </span>
                <br >You will be required to return to this screen and capture your reference number in
                the box provided below.
              </div>

              <div class="container">
                <div
                  class="row"
                  style="margin-bottom: 48px">
                  <div class="col-lg-12">
                    <a
                      href="https://www.standardbank.co.za/southafrica/personal/products-and-services/borrow-for-your-needs/car-financing/application"
                      class="btn btn-primary btn--FullWBtn approvalbtn"
                      target="_blank">
                      PROCEED TO FINANCE APPLICATION SITE
                    </a>

                    <input
                      v-validate="'required'"
                      v-model="reference"
                      data-vv-as="Reference"
                      data-vv-scope="finance"
                      name="reference"
                      type="text"
                      placeholder="Enter your reference number here"
                      class="form-control input-app_ref">
                    <div
                      v-show="errors.has('finance.reference')"
                      class="alert alert-danger">
                      {{ errors.first("finance.reference") }}
                    </div>

                    <div class="modal_text_2">Your total vehicle price to be financed is:</div>
                    <div class="modal_text_price">{{ totalBilledValue }}</div>

                    <div class="custom-control_new custom-checkbox confirmtext">
                      <input
                        v-validate="'required'"
                        :id="componentId"
                        v-model="confirm"
                        data-vv-scope="finance"
                        name="dealconfirm"
                        data-vv-as="Confirm Deal"
                        type="checkbox"
                        class="custom-control-input" >
                      <label
                        :for="componentId"
                        class="custom-control-label">
                        Please confirm your
                        deal
                      </label>
                    </div>
                    <div
                      v-show="errors.has('finance.dealconfirm')"
                      class="alert alert-danger">
                      {{ errors.first("finance.dealconfirm") }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="nextPrev(-1)">BACK</button>
                  </div>
                  <div class="col-lg-6">
                    <button
                      id="nextBtn"
                      type="button"
                      @click="submit('finance')">SUBMIT</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Circles which indicates the steps of the form: -->
            <div style="text-align:center;margin-top:40px;margin-bottom: 48px">
              <span class="step finish" />
              <span class="step" />
              <span class="step" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import swal from 'sweetalert2'

export default {
  props: {
    open: {
      type: Boolean,
      default:false
    },
    vehicle: {
      type:Object,
      required:true
    },
    products: {
      type:Array,
      required:true
    }
  },
  data() {
    return {
      currentTab:0,
      componentId: 0,
      reference:null,
      terms: false,
      confirm:false
    }
  },
  computed: {
    selectedProducts() {
      return _.filter(this.products, ['checked', true]);
    },
    totalBilledValue() {
      var total = 0
      _.forEach(this.products, (product) => {
        if(product.checked && product.financial_amount > 0) {
          total += parseFloat(product.financial_amount)
        }
      });
      total += this.vehicle.bare_buy_now_price

      return 'R '+total.toLocaleString(
        undefined,
        { minimumFractionDigits: 2 });//'R '+ _.round(total, 2)
    }
  },
  watch: {
    open(oldVal) {
      if(oldVal){
        $('.finance_price, .cash_price').removeClass('show');

        this.currentTab = 0;
        $('#buyModal'+this.componentId).modal('show');
        this.showTab(this.currentTab);
      }
    },
    currentTab() {
      if (this.currentTab == 0 ) {
        $('.finance_price, .cash_price').removeClass('show');
      }
    }
  },
  mounted() {
    this.componentId = this._uid
  },
  created() {
    let vueInstance = this;

    this.$nextTick(function() {
      $('#buyModal'+this.componentId+' #Cash_Btn').on('click',function(){
        $('.cash_price').addClass('show');
        $('.finance_price').removeClass('show');
      });

      $('#buyModal'+this.componentId+' #Fin_Btn').on('click',function(){
        $('.cash_price').removeClass('show');
        $('.finance_price').addClass('show');
      });

      $('#buyModal'+this.componentId).on('hidden.bs.modal', function () {
        vueInstance.$emit('modalClosed');
      });
    });
  },
  methods: {
    toFinalStep() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.nextPrev(1)
        }
      });
    },
    submit(scope) {
      let thisscope = this

      this.$validator.validateAll(scope).then(result => {
        if (result) {
          var checkedIds = [];
          _.forEach(this.products, function(product) {
            if(product.checked) {
              checkedIds.push(product.id)
            }
          });

          let data = {
            'type': scope,
            'vehicle_id': this.vehicle.id,
            'products' : checkedIds,
            'reference': this.reference,
            'term_accept':this.terms,
            'finance_confirm':this.confirm
          }

          this.$http
            .post("bankds/staff/buyreservation/reserve", data)
            .then(() => {

              this.$ga.event({
                eventCategory: 'buy',
                eventAction: 'buyvehicle',
                eventLabel: 'buyvehicle',
                eventValue: 6
              });

              //Emit message back to the reserve screen to reserve the vehicle
              thisscope.$emit('reserve');
              swal(
                "Reserved",
                "Your vehicle has been reserved and a consultant will contact you shortly. Check your inbox for an email confirmation.",
                "success"
              );
            })
            .catch(error => {
              if (error.response.data.status_code === 422) {
                swal("Oops", error.response.data.message, "info");
              } else if (error.response) {
                swal("Error", error.response.data.message, "error");
              }
          });
        }
      });
    },
    showTab(n) {
      $('#buyModal'+this.componentId+' .tab').hide();

      var x = $('#buyModal'+this.componentId+' .tab');

      x.eq(n).show()

      if (n == 0) {
        $("#prevBtn").hide();
      } else {
        $("#prevBtn").css("display", "inline");
      }

      this.fixStepIndicator(n)
    },
    nextPrev(n) {
      $('#buyModal'+this.componentId+' .tab').hide();
      var x = $('#buyModal'+this.componentId+' .tab');
      x.eq(this.currentTab).hide();
      this.currentTab = this.currentTab + n;
      this.showTab(this.currentTab);
    },
    fixStepIndicator(n) {
      var i, x = $('#buyModal'+this.componentId+' .step');
      for (i = 0; i < x.length; i++) {
        x.eq(i).removeClass('active');
      }
      x.eq(n).addClass('active');
    }
  }
}
</script>

<style>
</style>


