<template>
  <section>
    <div
      v-if="loadingWizard"
      class="loader"/>
    <section class="IntroBox intrologin">
      <div class="container d-md-flex justify-content-md-around">
        <div 
          class="CTaBlock" 
          style="margin-top: 24px;">
          <a 
            href="#" 
            title="Calculate your bid now">
            <picture class="Obj-Fit__HeroCTB">
              <source 
                media="(min-width: 1140px)" 
                srcset="@/assets/img/heroctb--dsk.png">
              <img 
                src="@/assets/img/heroctb.png" 
                alt="Calculate your bid now">
            </picture>
          </a>
        </div><!-- /CTaBlock -->
        <div class="vertical-center">
          <div class="login_header">Login</div>
          <div class="login_links">HOME  &gt;  LOGIN</div>
						
        </div><!-- /CTaText -->
      </div><!-- /container -->
    </section>
    <div 
      class="container" 
      style="margin-top: 48px; margin-bottom: 48px;">
      <div class="container loginblock">
        <div 
          class="ContactVdBlock" 
          style="max-width: 450px; margin-left: auto;margin-right: auto;">
          <div class="col-md-12">
            <h1 class="login_title">Login Now</h1>
     
     
            <h3 style="font-size: 16px; font-weight: 100">Login to our Website</h3>
            <!-- end row -->

            <h2 class="decorated"><span>SIGN IN</span></h2>

            <div class="row">
              <div class="col-sm-12 gForm">


                <div class="form-group">
                  <input
                    v-validate="'required|email'"
                    v-model="email"
                    type="email"
                    data-vv-as="Email"
                    name="username"
                    tabindex="1"
                    class="form-control input-lg"
                    placeholder="Email">
                  <span
                    v-show="errors.has('username')"
                    class="help-block">{{ errors.first('username') }}</span>
                </div>
                <div class="form-group">
                  <input
                    v-validate="'required'"
                    v-model="password"
                    type="password"
                    data-vv-as="Password"
                    name="password"
                    tabindex="2"
                    class="form-control input-lg"
                    placeholder="Password">
                  <span
                    v-show="errors.has('password')"
                    class="help-block">{{ errors.first('password') }}</span>
                </div>
                <div class="form-group">
                  <button 
                    class="btn btn-primary btn_login" 
                    @click="login(true)">LOGIN NOW</button>
                </div>
                <!-- end col -->
                <router-link 
                  to="/forgot" 
                  class="float-right"><a>Forgot Password</a></router-link>
              </div>
 
            </div>
          </div><!-- end VdBlock -->


        </div>
  </div></div></section>
</template>

<script>
import LoginMixin from "@/mixins/login";

export default {
  name: "Login",
  mixins: [LoginMixin]
};
</script>
