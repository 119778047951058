const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";
const SET_AUTH_USER = "SET_AUTH_USER";
const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";
const RESET_STATE = "RESET_STATE";

export default {
  state: {
    isLoggedIn: !!localStorage.getItem("token"),
    authUser: "",
    userVerification: "",
    subscriptions: "",
    company: "",
    address: "",
    wallet: 0
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    getAuthUser(state) {
      return state.authUser;
    },
    getAuthUserFullName(state) {
      if (state.authUser) {
        return (
          state.authUser.person.firstname + " " + state.authUser.person.lastname
        );
      } else {
        return null;
      }
    },
    getAuthUserIdNumber(state) {
      if (state.authUser) {
        return state.authUser.person.idnumber;
      } else {
        return null;
      }
    },
    getAuthUserEmail(state) {
      if (state.authUser) {
        return state.authUser.emailaddress;
      } else {
        return null;
      }
    },
    getCompany(state) {
      return state.company;
    },
    getAddress(state) {
      return state.address;
    },
    getAddressString(state) {
      const addressObj = state.address;
      return (
        addressObj.line1 +
        ", " +
        addressObj.line2 +
        ", " +
        addressObj.city +
        ", " +
        addressObj.province
      );
    },
    getProvince(state) {
      if (state.address) {
        const addressObj = state.address;
        return addressObj.province;
      }else {
        return null;
      }
    },
    getAuthUserId(state) {
      if (!state.authUser) {
        return null;
      } else {
        return state.authUser.id;
      }
    },
    getAutUserDisplayName(state) {
      if (state.authUser) {
        return state.authUser.person.firstname;
      }
      return null;
    },
    getAuthUserRole(state) {
      if (state.authUser) {
        return state.authUser.role;
      }
      return "";
    },
    isCompany(state) {
      return state.company.is_company;
    }
  },
  mutations: {
    [LOGIN_SUCCESS](state, payload) {
      state.authUser = payload.user;
      state.company = payload.company;
      state.address = payload.address;
      state.isLoggedIn = true;
    },
    [LOGOUT](state) {
      state.authUser = null;
      state.isLoggedIn = false;
    },
    [SET_AUTH_USER](state, authUser) {
      state.authUser = authUser;
    },
    [UPDATE_AUTH_USER](state, payload) {
      state.authUser.person.firstname = payload.user.firstname;
      state.authUser.person.lastname = payload.user.lastname;
    },
    [RESET_STATE](state) {
      state.isLoggedIn = false;
      state.authUser = "";
      state.company = "";
      state.address = "";
    }
  },
  actions: {
    login({ commit }, payload) {
      commit(LOGIN_SUCCESS, payload);
    },
    logout({ commit }) {
      commit(LOGOUT);
    },
    setAuthUser({ commit }, authUser) {
      commit(SET_AUTH_USER, authUser);
    },
    updateUser({ commit }, payload) {
      commit(UPDATE_AUTH_USER, payload);
    },
    clearUser({ commit }) {
      commit(RESET_STATE);
    }
  }
};
