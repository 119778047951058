<template>  
  <section class="IntroBox">
    <div class="container sliderblock d-md-flex justify-content-md-around">
      <div class="CTaBlock">
        <picture class="Obj-Fit__HeroCTA">
          <source 
            media="(min-width: 1140px)" 
            srcset="@/assets/img/herocta--dsk.png" >
          <source 
            media="(min-width: 992px)" 
            srcset="@/assets/img/herocta--med.png" >
          <img src="@/assets/img/herocta.png" >
        </picture>
      </div>
      <!-- /CTaBlock -->
      <div class="CTaText">
        <h2>
          New to {{ title }} a vehicle online?
          <span>It&rsquo;s real easy</span>
        </h2>
        <router-link 
          to="/getstarted" 
          class="btn btn-primary homebtn">HOW DOES IT WORK ?</router-link>
        <router-link 
          :to="faqUrl" 
          class="btn btn-primary homebtn">FAQ</router-link>
      </div>
    <!-- /CTaText -->
    </div>
  </section>
</template>

<script>
export default {
  name:"PricedToGo",
  props: {
    title: {
      type:String,
      default:''
    }
  },
  computed: {
    faqUrl() {
      if(this.title == 'buying') {
        return '/faq#buy'
      }else {
        return '/faq#rent'
      }
    }
  }
}
</script>

<style>

</style>