<template>
  <section>
    <div
      v-if="loadingWizard"
      class="loader"/>
    <section class="IntroBox intrologin">
      <div class="container d-md-flex justify-content-md-around">
        <div
          class="CTaBlock"
          style="margin-top: 24px;">
          <a
            href="#"
            title="Calculate your bid now">
            <picture class="Obj-Fit__HeroCTB">
              <source
                media="(min-width: 1140px)"
                srcset="@/assets/img/heroctb--dsk.png">
              <img
                src="@/assets/img/heroctb.png"
                alt="Calculate your bid now">
            </picture>
          </a>
        </div><!-- /CTaBlock -->
        <div class="vertical-center">
          <div class="login_header">Register</div>
          <div class="login_links">HOME  &gt;  REGISTER</div>

        </div><!-- /CTaText -->
      </div><!-- /container -->
    </section>
    <div
      class="container"
      style="margin-top: 48px; margin-bottom: 48px;max-width: 900px">

      <div style="text-align: center;">
        <h1 class="login_title">Registration</h1>


        <h3 style="font-size: 16px; font-weight: 100">Register to find your perfect rental deal</h3>
        <!-- end row -->

        <h3 style="font-size: 12px; font-weight: 100">* Denotes mandatory field. ** Atleast one telephone number is required.</h3>

      </div>

      <h2 class="decorated"><span>CREATE AN ACCOUNT</span></h2>

      <section>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 gForm">
            <div
              :class="errors.has('accountFirstName')?'has-error':''"
              class="form-group">
              <input
                v-validate="'required'"
                v-model="form.account_first_name"
                data-vv-as="Account First Name"
                name="accountFirstName"
                type="text"
                placeholder="* Your First Name"
                class="form-control input-lg">
              <span
                v-show="errors.has('accountFirstName')"
                class="help-block">{{ errors.first('accountFirstName') }}</span>
            </div>
            <div
              :class="errors.has('accountLastName')?'has-error':''"
              class="form-group">
              <input
                v-validate="'required'"
                v-model="form.account_last_name"
                data-vv-as="Account Last Name"
                name="accountLastName"
                type="text"
                placeholder="* Your Last Name"
                class="form-control input-lg">
              <span
                v-show="errors.has('accountLastName')"
                class="help-block">{{ errors.first('accountLastName') }}</span>
            </div>
            <div
              :class="errors.has('employeeCode')?'has-error':''"
              class="form-group">
              <input
                v-validate="{ rules: { required: true, regex:/^([ACac])\w+$/} }"
                v-model="form.employee_code"
                data-vv-as="Employee Code"
                name="employeeCode"
                type="text"
                placeholder="* Your Employee Number"
                class="form-control input-lg">
              <span
                v-show="errors.has('employeeCode')"
                class="help-block">{{ errors.first('employeeCode') }}</span>
            </div>
            <div
              :class="errors.has('accountCellNo')?'has-error':''"
              class="form-group">
              <input
                v-validate="{ rules: { required: true, numeric: true, regex:/^0[687][0123456789]((\d{7})|( |-)((\d{3}))( |-)(\d{4})|( |-)(\d{7}))$/} }"
                v-model="form.account_cell_no"
                data-vv-as="Account Cell Number"
                name="accountCellNo"
                type="text"
                placeholder="* Your Cell No"
                class="form-control input-lg">
              <span
                v-show="errors.has('accountCellNo')"
                class="help-block">{{ errors.first('accountCellNo') }}</span>
            </div>
            <div
              :class="errors.has('accountTelNo')?'has-error':''"
              class="form-group">
              <input
                v-validate="'required|numeric|max:10'"
                v-model="form.account_tel_no"
                data-vv-as="Account Telephone Number"
                name="accountTelNo"
                type="text"
                placeholder="* Your Work Tel Number"
                class="form-control input-lg">
              <span
                v-show="errors.has('accountTelNo')"
                class="help-block">{{ errors.first('accountTelNo') }}</span>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12 gForm bottomfrm">
            <div
              :class="errors.has('accountEmailAddress')?'has-error':''"
              class="form-group">
              <input
                v-validate="'required|email'"
                v-model="form.account_email_address"
                data-vv-as="Account Email Address"
                name="accountEmailAddress"
                type="text"
                placeholder="* Your Email Address (Username)"
                class="form-control input-lg">
              <span
                v-show="errors.has('accountEmailAddress')"
                class="help-block">{{ errors.first('accountEmailAddress') }}</span>
            </div>
            <div
              :class="errors.has('accountPassword')?'has-error':''"
              class="form-group">
              <input
                v-validate="'required|secure_password'"
                ref="accountPassword"
                v-model="form.account_password"
                data-vv-as="Account Password"
                name="accountPassword"
                type="password"
                placeholder="* Password"
                class="form-control input-lg">
              <span
                v-show="errors.has('accountPassword')"
                class="help-block">{{ errors.first('accountPassword') }}</span>
            </div>
            <div
              :class="errors.has('accountPasswordConfirm')?'has-error':''"
              class="form-group">
              <input
                v-validate="'required|confirmed:accountPassword'"
                v-model="form.account_password_confirmation"
                data-vv-as="Account Password Confirmation"
                name="accountPasswordConfirm"
                type="password"
                placeholder="* Password Confirmation"
                class="form-control input-lg">
              <span
                v-show="errors.has('accountPasswordConfirm')"
                class="help-block">{{ errors.first('accountPasswordConfirm') }}</span>
            </div>
            <div
              :class="errors.has('addressProvince')?'has-error':''"
              class="form-group">
              <select
                v-validate="'required'"
                v-model="form.address_province"
                placeholder="* Province"
                data-vv-as="Province"
                name="addressProvince"
                class="form-control custom-select searchbox">
                <option
                  value=""
                  selected>* Select Province</option>
                <option value="BOT">
                  Botswana
                </option>
                <option value="EC">
                  Eastern Cape
                </option>
                <option value="FS">
                  Freestate
                </option>
                <option value="GT">
                  Gauteng
                </option>
                <option value="KZN">
                  KwaZulu-Natal
                </option>
                <option value="LMP">
                  Limpopo
                </option>
                <option value="MP">
                  Mpumalanga
                </option>
                <option value="NAM">
                  Namibia
                </option>
                <option value="NC">
                  Northern Cape
                </option>
                <option value="NW">
                  North West
                </option>
                <option value="SWA">
                  Swaziland
                </option>
                <option value="WC">
                  Western Cape
                </option>
              </select>
              <span
                v-show="errors.has('addressProvince')"
                class="help-block">{{ errors.first('addressProvince') }}</span>
            </div>

            <div class="form-group">
              <div class="row checkboxreg">
                <div>
                  <div class="regcheckbox custom-control custom-checkbox2 ">
                    <input
                      v-validate="{ rules: { required: true }}"
                      id="AgreeId11"
                      v-model="form.agree_t_and_c"
                      name="accountTerms"
                      data-vv-as="Site Terms And Conditions"
                      type="checkbox"
                      class="custom-control-input">
                    <label
                      class="custom-control-label termstext"
                      for="AgreeId11">Agree to Terms &amp; Conditions
                      <router-link
                        to="/terms#rent"
                        target="_blank">
                        <a>View Terms</a>
                    </router-link></label>
                  </div>
                </div>
                <span
                  v-show="errors.has('accountTerms')"
                  class="help-block">{{ errors.first('accountTerms') }}</span>
              </div>
            </div>

            <div class="form-group">
              <vue-recaptcha
                ref="recaptcha"
                :sitekey="sitekey"
                @verify="onVerify"
                @expired="onExpired">
                <button
                  :disabled="status==='submitting'"
                  class="btn btn-primary"
                  @click="send">Register</button>
              </vue-recaptcha>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import swal from "sweetalert2";


export default {
  name: "Register",
  components: {
    FormWizard,
    TabContent,
    VueRecaptcha
  },
  data() {
    return {
      form: {
        site_url: process.env.VUE_APP_DOMAIN,
        entity_type: 2,
        address_line1: "NA",
        address_line2: "NA",
        address_province: "",
        address_city: "NA",
        billing_first_name: "",
        billing_last_name: "",
        billing_email_address: "",
        billing_tel_no: "",
        account_first_name: "",
        account_last_name: "",
        employee_code:"",
        account_cell_no: "",
        account_tel_no: "",
        account_email_address: "",
        account_password: "",
        account_password_confirmation: "",
        agree_t_and_c: false,
        verified_captcha_code: ""
      },
      loadingWizard: false,
      sitekey: "6LcC82gUAAAAAD8dTfzPZm4_YEr0RFZbsw8yMyFb",
      status: ""
    };
  },
  watch: {
    "form.account_first_name": function(newVal) {
      this.form.billing_first_name = newVal;
    },
    "form.account_last_name": function(newVal) {
      this.form.billing_last_name = newVal;
    },
    "form.account_email_address": function(newVal) {
      this.form.billing_email_address = newVal;
    },
    "form.account_tel_no": function(newVal) {
      this.form.billing_tel_no = newVal;
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    recaptchaScript.async = true;
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    onVerify: function(response) {
      const self = this;
      this.setLoading(true);
      self.status = "submitting";
      self.$refs.recaptcha.reset();
      self.status = "";
      this.setLoading(false);
      this.form.verified_captcha_code = response;
      this.register();
    },
    onExpired: function() {
      swal("Expired", "The recapcha has expired.", "error");
    },
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    send() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$refs.recaptcha.execute();
        }
      });
    },
    register() {
      this.setLoading(true);

      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http
            .post("bankds/staff/register", this.form)
            .then(response => {
              this.setLoading(false);
              if (response) {

                this.$ga.event({
                  eventCategory: 'register',
                  eventAction: 'register',
                  eventLabel: 'register',
                  eventValue: 5
                });

                swal(
                  "Almost there",
                  "You are now registered. You may now login.",
                  "success"
                );
                this.$router.push("/login");
              }
            })
            .catch(() => {
              this.setLoading(false);
          });
        }else {
          this.setLoading(false);
        }
      });
    }
  }
};
</script>

<style scoped>
span.error {
  color: #e74c3c;
  font-size: 20px;
  display: flex;
  justify-content: center;
}
@media (max-width: 575.98px) {
  .btn {
    min-width: 150px !important;
  }
}
@media (min-width: 576px) {
  .btn {
    min-width: 180px !important;
  }
}
</style>
