<template>
  <section class="InfoBar">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-lg-3">
          <div class="InfoBar__Box">
            <div class="InfoBar__Icon">
              <img 
                src="@/assets/img/speedo-ico.png" 
                alt="Icon" >
            </div>
            <!-- InfoBar__Icon -->
            <div class="InfoBar_TxtBox">
              <p class="InfoBar__Text">
                Low Mileage
                <br >Vehicles
              </p>
            </div>
            <!-- /InfoBar_TxtBox -->
          </div>
          <!-- /InfoBar__Box -->
        </div>
        <!-- /col -->
        <div class="col-xs-12 col-sm-6 col-lg-3">
          <div class="InfoBar__Box">
            <div class="InfoBar__Icon">
              <img 
                src="@/assets/img/weight.png" 
                alt="Icon" >
            </div>
            <!-- InfoBar__Icon -->
            <div class="InfoBar_TxtBox">
              <p class="InfoBar__Text">
                Balance of Factory
                <br >Warranty & Service Plan
              </p>
            </div>
            <!-- /InfoBar_TxtBox -->
          </div>
          <!-- /InfoBar__Box -->
        </div>
        <!-- /col -->
        <div class="col-xs-12 col-sm-6 col-lg-3">
          <div class="InfoBar__Box">
            <div class="InfoBar__Icon">
              <img 
                src="@/assets/img/graph-ico.png" 
                alt="Icon" >
            </div>
            <!-- InfoBar__Icon -->
            <div class="InfoBar_TxtBox">
              <p class="InfoBar__Text">
                Employee
                <br >Rates
              </p>
            </div>
            <!-- /InfoBar_TxtBox -->
          </div>
          <!-- /InfoBar__Box -->
        </div>
        <!-- /col -->
        <div class="col-xs-12 col-sm-6 col-lg-3">
          <div class="InfoBar__Box">
            <div class="InfoBar__Icon">
              <img 
                src="@/assets/img/buysell-ico.png" 
                alt="Icon" >
            </div>
            <!-- InfoBar__Icon -->
            <div class="InfoBar_TxtBox">
              <p class="InfoBar__Text">
                Less than 2 year-old
                <br >vehicles
              </p>
            </div>
            <!-- /InfoBar_TxtBox -->
          </div>
          <!-- /InfoBar__Box -->
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </section>
</template>

<script>
export default {
  name: "InfoBar"
};
</script>

<style>
</style>
