<template>
  <div
    v-match-heights="{
      el: ['.card'],// Array of selectors to fix
      disabled: [ // Array of heights where the plugin will not set the heights
        767, // If a string is provided this will be used as a max bound
        [920, 1200], // If an array is provided it will be used as min-max bounds in that order
      ]
    }"
    :class="thumbColSize"
    class="col-sm-12 col-md-4 col-lg-3"
    style="display: inline-table;">
    <router-link 
      :to="viewVehicleObject" 
      class="LinkBlock">
      <img 
        v-show="vehicle.reserved"
        class="reservedimg" 
        src="@/assets/img/reserved.png">
      <div 
        :class="vehicle.reserved?'cardreserved':''" 
        class="card">
        <div 
          v-show="vehicle.reserved" 
          class="reserved"/>
        <div class="Obj-Fit__RecentlyAdded">
          <figure
            v-lazy:background-image="imgObj"
            class="card-img-top layer"/>            
        </div>

        <!-- /Obj-Fit__RecentlyAdded -->
        <div class="Card-CounterBox d-flex justify-content-between">
          <div :class="vehicleRating" />
          <!-- /Card-StarsBox -->
        </div>
        <!-- /Card-CounterBox -->
        <div class="card-body">
          <p class="card-text">{{ price }}</p>
          <router-link
            :to="viewVehicleObject"
            tag="h5"
            class="card-title">
            {{ vehicle.display_name }}
          </router-link>
          <ul class="CarFeatureListing d-flex justify-content-between">
            <li>{{ vehicle.year }}</li>
            <li>{{ manualOrAuto }}</li>
            <li>{{ vehicle.mileage }}km</li>
          </ul>
        </div>
      </div>
      <!-- /card -->
    </router-link>    
    <!-- /LinkBlock -->
  </div>
  <!-- /col -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    thumbColSize: {
      type: String,
      required: true
    },
    vehicle: {
      type: Object,
      required: true
    },
    viewurl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imgObj: {
        src: this.vehicle.main_image,
        loading: "https://img.overtake.co.za/global/grid.svg"
      }
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    vehicleRating() {
      return (
        "Card-StarsBox Card-StarsBox--" +
        Math.ceil(this.vehicle.rating / 2) +
        "Stars"
      );
    },
    viewVehicleObject() {
      return {
        name: this.viewurl,
        params: {
          slug: this.vehicle.slug,
          vehicle_id: this.vehicle.id,
          search: this.$route.query
        }
      };
    },
    manualOrAuto() {
      if (this.vehicle.attributes.man_or_aut) {
        return this.vehicle.attributes.man_or_aut;
      } else {
        return null;
      }
    },
    price() {
      switch(this.vehicle.disposal_type) {
      case "BUY":
        return this.vehicle.buy_now_price;
      default:
        if(this.vehicle.from_price === null) {
          return 'Not Available'
        }else {
          return 'From '+this.vehicle.from_price
        }
      }
    }
  }
};
</script>

<style scoped>
.layer[lazy="loading"] {
  background-color: #35404f;
  background-size: 25%;
}
.layer {
  padding-bottom: 100%;
  width: 100%;
}
.layer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.layer {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>
