<template>
  <div
    :id="'rentModal'+componentId"
    class="modal show modalman"
    role="dialog">
    <!-- Modal content-->
    <div class="modal-dialog rental">
      <!-- Modal content-->
      <div class="modal-content">
        <!-- One "tab" for each step in the form: -->
        <div
          class="tab modal-tab">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal">&times;</button>
          </div>
          <h1 class="modal_head">Congratulations!</h1>
          <div class="modal_text">You have selected to rent this vehicle!</div>
          <div class="container">
            <div
              class="row"
              style="margin-bottom: 48px">
              <div class="col-lg-12 img-position">
                <img
                  class="modal_img"
                  src="@/assets/img//modalhand.png"
                  alt="">
              </div>
            </div>
            <div class="row select_payment">
              <div class="col-lg-12 select_btn">
                <button
                  id="rent_Btn"
                  type="button"
                  @click="nextPrev(1)">RENT NOW</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tab modal-tab">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal">&times;</button>
          </div>
          <h1 class="modal_head">Select Terms</h1>
          <div class="container">
            <div
              class="row"
              style="margin-bottom: 48px">
              <div class="col-lg-12">
                <div
                  v-show="errors.has('rate.rate')"
                  class="alert alert-danger">
                  {{ errors.first("rate.rate") }}
                </div>
                <table class="table_2">
                  <tr
                    v-for="(rate, index) in rates"
                    :key="index"
                    :class="'underline_tr'">
                    <td class="td_2">
                      {{ rate.months }} Months/{{ rate.annual_kms }}km
                    </td>
                    <td class="td_3">
                      R {{ rate.rental_price }}
                    </td>
                    <td class="td_2">
                      <div class="custom-control_new custom-checkbox">
                        <input
                          v-validate="'required'"
                          :id="rate.id"
                          :checked="rate.id == selectedRate.id"
                          data-vv-as="Rate"
                          name="rate"
                          data-vv-scope="rate"
                          type="checkbox"
                          class="custom-control-input"
                          @click="setRate(index)">
                        <label
                          :for="rate.id"
                          class="custom-control-label"/>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <button
                  id="prevBtn"
                  type="button"
                  @click="nextPrev(-1)">BACK</button>
              </div>
              <div class="col-lg-6">
                <button
                  id="nextBtn"
                  type="button"
                  @click="validateBeforeNextStep('rate')">NEXT STEP</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tab modal-tab">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal">&times;</button>
          </div>
          <h1 class="modal_head">Almost there!</h1>
          <div class="modal_text">You have selected a rental deal on a {{ vehicle.display_name }} with the following: </div>
          <div class="container">
            <div
              class="row"
              style="margin-bottom: 48px">
              <div class="col-lg-12">
                <table class="table_2">
                  <tr class="underline_tr">
                    <td class="td_2">
                      {{ selectedRate.months }} Months / {{ selectedRate.annual_kms }}km
                    </td>
                    <td class="td_39">
                      R {{ selectedRate.rental_price }}
                    </td>
                  </tr>
                </table>
                <table class="table_2">
                  <tr class="underline_tr">
                    <td class="td_4">
                      Your total vehicle rental is: <span class="td_price">R {{ selectedRate.rental_price }}</span>
                    </td>
                  </tr>
                  <tr class>
                    <td class="td_4">
                      <span>I accept the <router-link
                        target="_blank"
                        to="/terms#rent"> Terms and Conditions</router-link></span>
                      <div class="custom-control_new custom-checkbox">
                        <input
                          v-validate="'required'"
                          :id="componentId"
                          v-model="terms"
                          data-vv-scope="confirm"
                          name="terms"
                          data-vv-as="Terms and Conditions"
                          type="checkbox"
                          class="custom-control-input" >
                        <label
                          :for="componentId"
                          class="custom-control-label" />
                      </div>
                    </td>
                  </tr>
                </table>
                <div
                  v-show="errors.has('confirm.terms')"
                  class="alert alert-danger">
                  {{ errors.first("confirm.terms") }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <button
                  id="prevBtn"
                  type="button"
                  @click="nextPrev(-1)">Back</button>
              </div>
              <div class="col-lg-6">
                <button
                  id="nextBtn"
                  type="button"
                  @click="validateBeforeNextStep('confirm')">NEXT STEP</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tab modal-tab">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal">&times;</button>
          </div>


          <h1 class="modal_head">Final Step</h1>

          <div class="modal_text">You will now be redirected to the Standard Bank finance application website where you will receive a reference number. Please use the total vehicle deal price when completing your application. This process is merely a finance application, our agents will be in contact after you have completed your application to discuss your options.
          </div>

          <div class="modal_text"><span style="color: #0089FF; font-weight: 600">Please Note:</span><br> You will be required to return to this screen and capture your reference number in the box provided below.</div>

          <div class="container">
            <div
              class="row"
              style="margin-bottom: 48px">
              <div class="col-lg-12">
                <a
                  href="https://www.standardbank.co.za/southafrica/personal/products-and-services/borrow-for-your-needs/car-financing/application"
                  class="btn btn-primary btn--FullWBtn approvalbtn"
                  target="_blank">
                  PROCEED TO FINANCE APPLICATION SITE
                </a>

                <input
                  v-validate="'required'"
                  v-model="reference"
                  data-vv-as="Reference"
                  data-vv-scope="submit"
                  name="reference"
                  type="text"
                  placeholder="Enter your Reference number here"
                  class="form-control input-app_ref">

                <div
                  v-show="errors.has('submit.reference')"
                  class="alert alert-danger"
                  style="margin-top: 24px;">
                  {{ errors.first("submit.reference") }}
                </div>

                <div class="modal_text_2">
                  Your total vehicle rental is:</div>
                <div class="modal_text_price">
                  {{ selectedRate.rental_price_text }}</div>
                <div class="custom-control_new custom-checkbox confirmtext">
                  <div
                    v-show="errors.has('submit.confirm')"
                    class="alert alert-danger">
                    {{ errors.first("submit.confirm") }}
                  </div>
                  <input
                    v-validate="'required'"
                    :id="'box57'"
                    v-model="confirm"
                    data-vv-scope="submit"
                    name="confirm"
                    data-vv-as="Confirm deal"
                    type="checkbox"
                    class="custom-control-input" >
                  <label
                    :for="'box57'"
                    class="custom-control-label">Please confirm your deal</label>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-lg-6">
                <button
                  id="prevBtn"
                  type="button"
                  @click="nextPrev(-1)">BACK</button>
              </div>

              <div class="col-lg-6">

                <button
                  id="nextBtn"
                  type="button"
                  @click="submit('submit')">SUBMIT</button>
              </div>
            </div>
          </div>

        </div>

        <!-- Circles which indicates the steps of the form: -->
        <div
          v-show="currentTab > 0"
          style="text-align:center;margin-top:40px;margin-bottom: 48px">
          <span class="step active" />
          <span class="step" />
          <span class="step" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'

export default {
  props: {
    open: {
      type: Boolean,
      default:false
    },
    vehicle: {
      type:Object,
      required:true
    },
    rates: {
      type:Array,
      required:true
    }
  },
  data() {
    return {
      currentTab:0,
      componentId: 0,
      reference:null,
      terms: false,
      confirm:false,
      selectedRate: {
        id: null,
        months: null
      }
    }
  },
  watch: {
    open(oldVal) {
      if(oldVal){
        $('.rental').removeClass('show');
        this.currentTab = 0;
        $('#rentModal'+this.componentId).modal('show');
        this.showTab(this.currentTab);
      }
    }
  },
  mounted() {
    this.componentId = this._uid
  },
  created() {
    /*let vueInstance = this;
    this.$nextTick(function() {
      $('#rentModal'+this.componentId+' #Cash_Btn').on('click',function(){
        $('.cash_price').addClass('show');
        $('.finance_price').removeClass('show');
      });

      $('#rentModal'+this.componentId+' #Fin_Btn').on('click',function(){
        $('.cash_price').removeClass('show');
        $('.finance_price').addClass('show');
      });

      $('#rentModal'+this.componentId).on('hidden.bs.modal', function () {
        vueInstance.$emit('modalClosed');
      });
    });*/
  },
  methods: {
    setRate(rateIndex) {
      if (this.selectedRate == this.rates[rateIndex]) {
        this.selectedRate = null;
      } else {
        this.selectedRate = this.rates[rateIndex];
      }
    },
    validateBeforeNextStep(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          this.nextPrev(1)
        }
      });
    },
    submit(scope) {
      let thisscope = this;
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          let data = {
            'vehicle_id': this.vehicle.id,
            'scheme_id':  this.selectedRate.id,
            'term_accept':this.terms,
            'reference': this.reference,
            'rent_confirm':this.confirm
          }
          this.$http
            .post("bankds/staff/rentalreservation/reserve", data)
            .then(() => {
              this.$ga.event({
                eventCategory: 'rent',
                eventAction: 'rentvehicle',
                eventLabel: 'rentvehicle',
                eventValue: 7
              });
              this.reservedUserId = this.getAuthUserId;
              this.reservedVehicleId = this.vehicle.id;
              thisscope.$emit('reserve');
              swal(
                "Reserved",
                "Your vehicle has been reserved and a consultant will contact you shortly. Check your inbox for an email confirmation.",
                "success"
              );
            })
            .catch(error => {
              if (error.response.data.status_code === 422) {
                swal("Oops", error.response.data.message, "info");
              } else if (error.response) {
                swal("Error", error.response.data.message, "error");
              }
          });
        }
      });
    },
    showTab(n) {
      $('#rentModal'+this.componentId+' .tab').hide();
      var x = $('#rentModal'+this.componentId+' .tab');
      x.eq(n).show()
      if (n == 0) {
        $("#prevBtn").hide();
      } else {
        $("#prevBtn").css("display", "inline");
        this.fixStepIndicator(n)
      }
    },
    nextPrev(n) {
      if (n > 0) {
        document.getElementsByClassName("step")[this.currentTab].className += " finish";
      }
      $('#rentModal'+this.componentId+' .tab').hide();
      var x = $('#rentModal'+this.componentId+' .tab');
      x.eq(this.currentTab).hide();
      this.currentTab = this.currentTab + n;
      this.showTab(this.currentTab);
    },
    fixStepIndicator(n) {
      var i, x = $('#rentModal'+this.componentId+' .step');
      for (i = 0; i < x.length; i++) {
        x.eq(i).removeClass('active');
      }
      x.eq(n).addClass('active');
    }
  }
}
</script>

<style>
</style>


