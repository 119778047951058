<template>
  <section class="IntroBox">
    <div class="container button_group">
      <div class="row">
        <div class="col-md-4">
          <router-link 
            tag="div" 
            class="red_btn" 
            to="/intro/rent">
            Rent-a-car
          </router-link>
        </div>

        <div class="col-md-4">
          <router-link 
            tag="div" 
            class="orange_btn" 
            to="/intro/buy">
            Buy-a-car
          </router-link>
        </div>

        <div class="col-md-4">
          <router-link 
            tag="div" 
            class="green_btn" 
            to="/intro/sell">
            Sell-a-car
          </router-link>
        </div>
      </div>
    </div>
  </section><!-- /IntroBox -->
</template>

<script>
export default {
  name:"IntroBox"
}
</script>

<style>

</style>