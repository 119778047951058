<template>
  <section id="getstarted">
    <div 
      id="carslGetStarted" 
      class=" carousel slide" 
      data-ride="carousel">

      <div class="container bottomheader2 d-flex flex-column align-items-center">
        <h1 class="herobottom">Get started with these Simple Steps.</h1>


        <ol class="CarslGetStartedList CarslGetStartedList--Mobile">
          <li>Register</li>
          <li>Find your vehicle</li>
          <li>Select Package</li>
          <li>Application</li>
          <li>Sign Contract</li>
          <li>Take Delivery!</li>
        </ol><!-- /CarslGetStartedList--Mobile -->
	
        <ul class="carousel-indicators CarslGetStartedList CarslGetStartedList--Desktop">
          <li 
            data-target="#carslGetStarted" 
            data-slide-to="0" 
            class="active"><img 
              class="slidenumber1" 
              src="@/assets/img/number.png"><span>Register</span> &gt; </li>
          <li 
            data-target="#carslGetStarted" 
            data-slide-to="1"><img 
              class="slidenumber2" 
              src="@/assets/img/number2.png"><span>Find your vehicle</span> &gt; </li>
          <li 
            data-target="#carslGetStarted" 
            data-slide-to="2"><img 
              class="slidenumber3" 
              src="@/assets/img/number3.png"><span>Select Package</span>&gt;</li>
          <li 
            data-target="#carslGetStarted" 
            data-slide-to="3"><img 
              class="slidenumber4" 
              src="@/assets/img/number4.png"><span>Application</span> &gt; </li>
          <li 
            data-target="#carslGetStarted" 
            data-slide-to="4"><img 
              class="slidenumber5" 
              src="@/assets/img/number5.png"><span>Sign Contract</span> &gt; </li>
          <li 
            data-target="#carslGetStarted" 
            data-slide-to="5"><img 
              class="slidenumber6" 
              src="@/assets/img/number6.png"><span>Take Delivery!</span></li>
        </ul><!-- /CarslGetStartedList--Desktop -->
        <div class="carousel-inner d-lg-flex">
          <section class="bottomheader carousel-item active">
            <div class="container">


              <div class="hero_bottom_strip center">
                <div class="row">
                  <div class="col-lg-4 col-md-3 float-right guy_left">
                    <img 
                      src="@/assets/img/guy_left.png" 
                      alt="" >
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 mobile-align">
                    <div class="col-lg-12 searchico">
                      <img src="@/assets/img/search.png" >
                    </div>
                    <div class="col-lg-12">
                      <div class="regtext">REGISTER</div>
                    </div>
                    <div class="col-lg-12">
                      <div class="signtext">SIGN UP BELOW TO REGISTER</div>
                    </div>
                    <div 
                      v-show="!isLoggedIn" 
                      class="col-lg-12">
                      <div class="row some_class">
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn loginbtn btn-primary" 
                            to="/login">
                            LOGIN
                          </router-link>
                        </div>
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn signupbtn btn-primary" 
                            to="/register">
                            SIGN UP
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-3 float-left girl_right">
                    <img src="@/assets/img/girl_right.png" >
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="bottomheader carousel-item">
            <div class="container">
		

              <div class="hero_bottom_strip center">
                <div class="row">
                  <div class="col-lg-4 col-md-3 float-right guy_left">
                    <img src="@/assets/img/guy_left_2.png" >
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 mobile-align">
                    <div class="col-lg-12 searchico">
                      <img 
                        src="@/assets/img/search.png" 
                        alt="">
                    </div>
                    <div class="col-lg-12">
                      <div class="regtext">SEARCH</div>
                    </div>
                    <div class="col-lg-12">
                      <div class="signtext">CAR MAKE OR MODEL.</div>
                    </div>
                    <div 
                      v-show="!isLoggedIn" 
                      class="col-lg-12">
                      <div class="row some_class">
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn loginbtn btn-primary" 
                            to="/login">
                            LOGIN
                          </router-link>
                        </div>
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn signupbtn btn-primary" 
                            to="/register">
                            SIGN UP
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-3 float-left girl_right">
                    <img src="@/assets/img/girl_right_2.png" >
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="bottomheader carousel-item">
            <div class="container">


              <div class="hero_bottom_strip center carousel-inner ">
                <div class="row">
                  <div class="col-lg-4 col-md-3 float-right guy_left">
                    <img 
                      src="@/assets/img/guy_left_3.png" 
                      alt="" >
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 mobile-align">
                    <div class="col-lg-12 searchico">
                      <img 
                        src="@/assets/img/calc.png" 
                        alt="">
                    </div>
                    <div class="col-lg-12">
                      <div class="regtext">SELECT PACKAGE</div>
                    </div>
                    <div class="col-lg-12">
                      <div class="signtext">CHOOSE TERM & MILEAGE.</div>
                    </div>
                    <div 
                      v-show="!isLoggedIn" 
                      class="col-lg-12">
                      <div class="row some_class">
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn loginbtn btn-primary" 
                            to="/login">
                            LOGIN
                          </router-link>
                        </div>
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn signupbtn btn-primary" 
                            to="/register">
                            SIGN UP
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-3 float-left girl_right">
                    <img 
                      src="@/assets/img/girl_right_3.png" 
                      alt="" >
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="bottomheader carousel-item">
            <div class="container">
		

              <div class="hero_bottom_strip center carousel-inner ">
                <div class="row">
                  <div class="col-lg-3 col-md-3 float-right guy_left">
                    <img 
                      src="@/assets/img/guy_left_4.png" 
                      alt="" >
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 mobile-align">
                    <div class="col-lg-12 searchico">
                      <img 
                        src="@/assets/img/money.png" 
                        alt="">
                    </div>
                    <div class="col-lg-12">
                      <div class="regtext">APPLICATION OUTCOME</div>
                    </div>
                    <div class="col-lg-12">
                      <div class="signtext">WE WILL BE IN TOUCH TO DISCUSS THE CONTRACT.</div>
                    </div>
                    <div 
                      v-show="!isLoggedIn" 
                      class="col-lg-12">
                      <div class="row some_class">
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn loginbtn btn-primary" 
                            to="/login">
                            LOGIN
                          </router-link>
                        </div>
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn signupbtn btn-primary" 
                            to="/register">
                            SIGN UP
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 float-left girl_right">
                    <img 
                      src="@/assets/img/girl_right_4.png" 
                      alt="" >
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="bottomheader carousel-item">
            <div class="container">


              <div class="hero_bottom_strip center carousel-inner ">
                <div class="row">
                  <div class="col-lg-4 col-md-3 float-right guy_left">
                    <img 
                      src="@/assets/img/guy_left_5.png" 
                      alt="" >
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 mobile-align">
                    <div class="col-lg-12 searchico">
                      <img 
                        src="@/assets/img/note.png" 
                        alt="">
                    </div>
                    <div class="col-lg-12">
                      <div class="regtext">SIGN CONTRACT</div>
                    </div>
                    <div class="col-lg-12">
                      <div class="signtext">WE WILL NEED YOUR AUTOGRAPH.</div>
                    </div>
                    <div 
                      v-show="!isLoggedIn" 
                      class="col-lg-12">
                      <div class="row some_class">
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn loginbtn btn-primary" 
                            to="/login">
                            LOGIN
                          </router-link>
                        </div>
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn signupbtn btn-primary" 
                            to="/register">
                            SIGN UP
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-3 float-left girl_right">
                    <img 
                      src="@/assets/img/girl_right_5.png" 
                      alt="" >
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="bottomheader carousel-item">
            <div class="container">

              <div class="hero_bottom_strip center carousel-inner ">
                <div class="row">
                  <div class="col-lg-4 col-md-3 float-right guy_left">
                    <img 
                      src="@/assets/img/guy_left_3.png" 
                      alt="" >
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 mobile-align">
                    <div class="col-lg-12 searchico">
                      <img 
                        src="@/assets/img/truck.png" 
                        alt="">
                    </div>
                    <div class="col-lg-12">
                      <div class="regtext">TAKE DELIVERY</div>
                    </div>
                    <div class="col-lg-12">
                      <div class="signtext">DRIVE AWAY IN YOUR DREAM CAR!</div>
                    </div>
                    <div 
                      v-show="!isLoggedIn" 
                      class="col-lg-12">
                      <div class="row some_class">
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn loginbtn btn-primary" 
                            to="/login">
                            LOGIN
                          </router-link>
                        </div>
                        <div class="col-smd-12 col-slg-6">
                          <router-link 
                            class="btn signupbtn btn-primary" 
                            to="/register">
                            SIGN UP
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-3 float-left girl_right">
                    <img 
                      src="@/assets/img/girl_right_6.png" 
                      alt="" >
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GetStarted",
  computed: {
    ...mapGetters([
      "isLoggedIn"
    ])
  }
};
</script>

<style>
</style>
