<template>
  <section class="GeneralPage">
    <div
      v-if="loadingWizard"
      class="loader"/>
    <div
      class="container" 
      style="margin-bottom: 48px;">
      <h1 class="title">
        <i class="fa fa-lock mr-5" /> My Profile
      </h1>
      <hr>
      <div class="row">
        <account-navigation />
        <div class="col-sm-9 gForm gForm--SftBg">
          <div class="row">
            <div class="col-lg-12">
              <div
                :class="errors.has('firstName')?'has-error':''"
                class="form-group">
                <input
                  v-validate="'required'"
                  v-model="first_name"
                  data-vv-as="First Name"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  class="form-control input-sm required">
                <span
                  v-show="errors.has('firstName')"
                  class="help-block">{{ errors.first('firstName') }}</span>
              </div><!-- end form-group -->
              <div
                :class="errors.has('lastName')?'has-error':''"
                class="form-group">
                <input
                  v-validate="'required'"
                  v-model="last_name"
                  data-vv-as="Last Name"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  class="form-control input-sm required">
                <span
                  v-show="errors.has('lastName')"
                  class="help-block">{{ errors.first('lastName') }}</span>
              </div><!-- end form-group -->
              <div
                :class="errors.has('accountCellNo')?'has-error':''"
                class="form-group">
                <input
                  v-validate="{ rules: { required: true, numeric: true, regex:/^0[687][0123456789]((\d{7})|( |-)((\d{3}))( |-)(\d{4})|( |-)(\d{7}))$/} }"
                  v-model="cell"
                  data-vv-as="Cell Number"
                  type="text"
                  placeholder="Your Cell No"
                  name="accountCellNo"
                  class="form-control input-sm required email"
                  disabled>
                <span
                  v-show="errors.has('accountCellNo')"
                  class="help-block">{{ errors.first('accountCellNo') }}</span>
              </div><!-- end form-group -->
            </div><!-- end col -->
          </div><!-- end row -->         
          <hr class="spacer-20 no-border">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <button
                  class="btn btn-primary float-right text-center col-sm-12"
                  @click="updateProfile()">
                  Save
                </button>
              </div><!-- end form-group -->
            </div>
          </div>
        </div><!-- end col -->
      </div><!-- end row -->
    </div><!-- end container -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";

import AccountNav from "../layouts/navigation/AccountNav.vue";

export default {
  name: "UserProfile",
  components: {
    AccountNavigation: AccountNav
  },
  data() {
    return {
      loadingWizard: false,
      first_name: "",
      last_name: "",
      cell: ""
    };
  },
  computed: {
    ...mapGetters(["getAuthUser", "getSubscriptions"])
  },
  created() {
    const authUser = this.getAuthUser;
    const authPerson = authUser.person;

    this.first_name = authPerson.firstname;
    this.last_name = authPerson.lastname;
    this.cell = authPerson.cell;
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    updateProfile() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const data = {
            account_first_name: this.first_name,
            account_last_name: this.last_name,
            account_cell_no: this.cell
          };
          this.setLoading(true);
          this.$http
            .put("user/profile", data)
            .then(response => {
              if (response) {
                this.setLoading(false);
                this.$store.dispatch("updateUser", {
                  user: response.data.data, /*user details*/
                  subscription:null
                });
               
                swal("Success", "Profile updated", "success");
              }
            })
            .catch(() => {
              this.setLoading(false);
              return Promise.resolve(true);
          });
        }
      });
    }
  }
};
</script>
