<template>
  <div>
    <info-bar />
    <section class="IntroSelect">
      <div class="container">
        <div class="row car_block">          
          <router-link 
            tag="div" 
            class="col-lg-4 carselectblockA" 
            style="cursor: pointer;"
            to="/intro/rent">
            <img 
              id="BlockImgA" 
              src="@/assets/img/jeep-img.png" 
              alt >
            <div id="BlockA">
              <h1>Rent-a-car</h1>              
              <i class="far fa-arrow-alt-circle-right select_link" />             
            </div>
          </router-link>

          <router-link 
            tag="div" 
            class="col-lg-4 carselectblockB"
            style="cursor: pointer;"
            to="/intro/buy">        
            <img 
              id="BlockImgB" 
              src="@/assets/img/hatch-img.png" 
              alt >
            <div id="BlockB">
              <h1>Buy-a-car</h1>
              <i class="far fa-arrow-alt-circle-right select_link" />     
            </div>
          </router-link>

          <router-link 
            tag="div" 
            class="col-lg-4 carselectblockC"
            style="cursor: pointer;"
            to="/intro/sell">   
            <img 
              id="BlockImgC" 
              src="@/assets/img/prius-img.png" 
              alt >
            <div id="BlockC">
              <h1>Sell-a-car</h1>
              <i class="far fa-arrow-alt-circle-right select_link" />              
            </div>
          </router-link>
        </div>
      </div>
      <!-- /container -->
    </section>
    <todays-featured-vehicles />
  </div>
  <!-- /IntroBox -->
</template>

<script>
import { mapGetters } from "vuex";

import TodaysFeaturedVehicles from "./TodaysFeaturedVehicles";
import GetStarted from "../elements/GetStarted";
import InfoBar from "../elements/HomeInfoBar";

export default {
  name: "Index",
  components: {
    TodaysFeaturedVehicles,
    GetStarted,
    InfoBar
  },
  computed: {
    ...mapGetters(["getTotalVehicles"])
  }
};
</script>
