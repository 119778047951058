import swal from "sweetalert2";

export default {
  data() {
    return {
      email: "",
      password: "",
      remember_me: false,
      loadingWizard: false
    };
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    login(redirect = false) {
      this.$validator.validateAll().then(result => {
        if (result) {
          var data = {
            emailaddress: this.email,
            password: this.password,
            remember_me: this.remember_me,
            include: "subscription,company,userverification,address"
          };
          //Get the auth token and expired time
          this.setLoading(true);
          this.$http
            .post("bankds/staff/auth", data)
            .then(response => {
              this.setLoading(false);
              this.$auth.setToken(response.data.data.token);
              //Sets login to true
              this.$store.dispatch("login", {
                depositMade:
                  response.data.data.deposited /*deposit has been made*/,
                user: response.data.data.user /*user details*/,
                subscription:
                  response.data.data.subscription.data /*subscription details*/,
                company: response.data.data.company.data /*company details*/,
                address: response.data.data.address.data /*address details*/,
                userverification:
                  response.data.data.userverification
                    .data /*subscription details*/
              });

              if (redirect) this.$router.push("/index");
              else this.$emit("closeLoginModal");
            })
            .catch(error => {
              this.setLoading(false);
              if (error.response) {
                swal("Error", error.response.data.message, "error");
              }
          });
        }
      });
    }
  }
};
