<template>
  <section class="HomePage__HeaderBox">
    <section class="Main__TopNav">
      <nav class="navbar navbar-expand-lg navbar-dark">
        <div class="container">
          <button
            class="navbar-toggler custom-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#auctionNavbarToggler"
            aria-controls="auctionNavbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon" />
          </button>
          <router-link to="/">
            <picture class="Obj-Fit__NavLogo">
              <source
                media="(min-width: 1440px)"
                srcset="@/assets/img/navlogo--dsk.png" >
              <img
                src="@/assets/img/navlogo.png"
                alt="Auction Online logo" >
            </picture>
          </router-link>
          <div
            id="auctionNavbarToggler"
            class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto NavBarNav">
              <router-link
                to="/rent"
                tag="li"
                class="nav-item nav-item--Rent">
                <a
                  class="nav-link"
                  href="#"><span>Rent-a-car</span></a>
              </router-link>
              <router-link
                to="/buy"
                tag="li"
                class="nav-item nav-item--Buy">
                <a
                  class="nav-link"
                  href="#"><span>Buy-a-car</span></a>
              </router-link>
              <router-link
                to="/sell"
                tag="li"
                class="nav-item nav-item--Sell">
                <a
                  class="nav-link"
                  href="#"><span>Sell-a-car</span></a>
              </router-link>
            </ul>
            <ul class="navbar-nav NavBarTools">
              <router-link
                v-if="!isLoggedIn"
                to="/login"
                tag="li"
                class="nav-item nav-item--Login">
                <a
                  class="nav-link"
                  href="#">
                  <img
                    src="@/assets/img/log-in-ico-wide.png"
                    alt="Login" >
                  <span style="font-size: 12px">Log-in</span>
                </a>
              </router-link>
              <router-link
                v-if="!isLoggedIn"
                to="/register"
                tag="li"
                class="nav-item nav-item--SignUp">
                <a
                  class="nav-link"
                  href="#">
                  <img
                    src="@/assets/img/account-ico-wide.png"
                    alt="Signup" >
                  <span style="font-size: 12px">Sign-up</span>
                </a>
              </router-link>
              <li
                v-if="isLoggedIn"
                class="nav-item nav-item--Watchlist dropdown d-lg-block show">
                <a
                  id="helpMenu"
                  class="nav-link LastLinkBdR dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <img
                    src="@/assets/img/account-ico-wide.png"
                    alt="Search">
                  <span style="font-size: 12px">Account</span>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="helpMenu">

                  <router-link
                    to="/profile"
                    tag="li"
                    class="dropdown-item">
                    <a>My Profile</a>
                  </router-link>

                  <router-link
                    to="/logout"
                    tag="li"
                    class="dropdown-item"
                    @click.native="logout">
                    <a>Logout</a>
                  </router-link>
                </div>
              </li>
              <li class="nav-item nav-item--Help dropdown">
                <a
                  id="helpMenu"
                  class="nav-link LastLinkBdR dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="position: relative;">
                  <img
                    src="@/assets/img/help-ico-wide.png"
                    alt="Help" >
                  <span style="border-bottom-color: transparent; font-size: 12px">Help</span>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="helpMenu">
                  <div class="container">
                    <table class="help_table">
                      <tr class="help_links">
                        <td>
                          <router-link
                            class="dropdown-item"
                            to="/rent">
                            Rent-a-car
                          </router-link>
                        </td>
                        <td>
                          <router-link
                            class="dropdown-item"
                            to="/faq#rent">
                            FAQ
                          </router-link>
                        </td>
                        <td>
                          <router-link
                            class="dropdown-item"
                            to="/terms#rent">
                            Legal stuff
                          </router-link>
                        </td>
                      </tr>
                      <tr class="help_links">
                        <td>
                          <router-link
                            class="dropdown-item"
                            to="/buy">
                            Buy-a-car
                          </router-link>
                        </td>
                        <td>
                          <router-link
                            class="dropdown-item"
                            to="/faq#buy">
                            FAQ
                          </router-link>
                        </td>
                        <td>
                          <router-link
                            class="dropdown-item"
                            to="/terms#buy">
                            Legal stuff
                          </router-link>
                        </td>
                      </tr>
                      <tr
                        v-show="isLoggedIn"
                        class="help_links">
                        <td>
                          <router-link
                            to="/contactus"
                            class="dropdown-item">
                            Contact us
                          </router-link>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- /container -->
      </nav>
    </section>
    <!-- /TopNav -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isAuth: null
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getAutUserDisplayName", "getAuthUserId"])
  },
  methods: {
    logout() {
      this.$http
        .delete("logout")
        .then(() => {
          this.$auth.logout();
        })
        .catch(() => {
          this.$auth.logout();
          return Promise.resolve(true);
      });
    }
  }
};
</script>

<style scoped>
.link {
  cursor: pointer;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
</style>
