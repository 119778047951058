<template>
  <section class="InfoBar">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-lg-3">
          <div class="InfoBar__Box">
            <div class="InfoBar__Icon">
              <img 
                src="@/assets/img/wallet-ico.png" 
                alt="Icon" >
            </div><!-- InfoBar__Icon -->
            <div class="InfoBar_TxtBox">
              <p class="InfoBar__Text">Personalize <br>
                your finance</p>
            </div><!-- /InfoBar_TxtBox -->
          </div><!-- /InfoBar__Box -->
        </div><!-- /col -->
        <div class="col-xs-12 col-sm-6 col-lg-3">
          <div class="InfoBar__Box">
            <div class="InfoBar__Icon">
              <img 
                src="@/assets/img/graph-ico.png" 
                alt="Icon" >
            </div><!-- InfoBar__Icon -->
            <div class="InfoBar_TxtBox">
              <p class="InfoBar__Text">Employee <br>
                Interest Rates</p>
            </div><!-- /InfoBar_TxtBox -->
          </div><!-- /InfoBar__Box -->
        </div><!-- /col -->
        <div class="col-xs-12 col-sm-6 col-lg-3">
          <div class="InfoBar__Box">
            <div class="InfoBar__Icon">
              <img 
                src="@/assets/img/hand-ico.png" 
                alt="Icon" >
            </div><!-- InfoBar__Icon -->
            <div class="InfoBar_TxtBox">
              <p class="InfoBar__Text">Cash Purchase<br> Optional</p>
            </div><!-- /InfoBar_TxtBox -->
          </div><!-- /InfoBar__Box -->
        </div><!-- /col -->
        <div class="col-xs-12 col-sm-6 col-lg-3">
          <div class="InfoBar__Box">
            <div class="InfoBar__Icon">
              <img 
                src="@/assets/img/buysell-ico.png" 
                alt="Icon" >
            </div><!-- InfoBar__Icon -->
            <div class="InfoBar_TxtBox">
              <p class="InfoBar__Text">Employee Vehicle<br> Pricing</p>
            </div><!-- /InfoBar_TxtBox -->
          </div><!-- /InfoBar__Box -->
        </div><!-- /col -->
      </div><!-- /row -->
    </div><!-- /container -->
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>