<template>
  <section class="IntroBox Whoops">
    <div class="container d-md-flex justify-content-md-around">
      <div class="404CTaText col-lg-6">
        <h2 class="whoops404"><span>WHOOPS!</span></h2>
        <h2 class="whoops40432">LOOKS LIKE YOU <br>HIT A POTHOLE!</h2>
        <p class="whoops404text">We can’t seem to find the<br> page you’re looking for.</p>
        <p class="whoops404textb"><strong>ERROR CODE: 404</strong></p>
        <div class="col-lg-6 nopadding ">
          <p class="whoops404textc">Here are some helpful links instead:</p>
          <div class="col-lg-6 col-md-6 float-left nopadding ">
            <router-link to="/home">
              Home
            </router-link>
            <br>
            <router-link to="/rent">
              Buy
            </router-link>
            <br>
            <router-link to="/sell">
              Sell
            </router-link>
          </div>
          <div class="col-lg-6 col-md-6 float-left nopadding "/>
        </div>
      </div><!-- /CTaText -->
      <div class="Whoops404CTaBlock col-lg-6">
        <img 
          class="img-responsive" 
          src="@/assets/img/404cta--dsk.png" 
          alt="Calculate your bid now">
      </div><!-- /CTaBlock -->
			
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "Error404",
  data() {
    return {
      canGoBack: window.history.length > 1
    };
  },
  methods: {
    goBack() {
      window.history.go(-1);
    }
  }
};
</script>

<style>
.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}
</style>
