<template>
  <section class="RecentlyAdded FindResultOutput">
    <div class="container">
      <h1>
        Recently
        <span>Added</span>
      </h1>
      <div class="row">
        <vehicle 
          v-for="vehicle in latestVehicles" 
          :key="vehicle.vehicle_id" 
          :thumb-col-size="'col-md-2'" 
          :vehicle="vehicle"
          :viewurl="dynamicUrl(vehicle)"/>
      </div>
    </div>
  </section>
</template>

<script>
import swal from "sweetalert2";
import dynamicUrlMixin from "@/mixins/dynamicUrl";

import Vehicle from "../vehicle/Vehicle";

export default {
  name: "NewVehicles",
  components: {
    Vehicle
  },
  mixins: [dynamicUrlMixin],
  props: {
    vehicleUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      latestVehicles: []
    };
  },
  created() {
    this.$http
      .get(this.vehicleUrl)
      .then(response => {
        this.latestVehicles = response.data.data;
        this.$store.dispatch(
          "setTotalVehicleCount",
          response.data.meta.pagination.total
        );
      })
      .catch(error => {
        if (error.response) swal("Error", error.response.data.message, "error");
    });
  }
};
</script>

<style>
</style>
