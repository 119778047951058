<template>
  <div 
    v-if="showBreadcrumb" 
    class="container" 
    style="margin-top: 15px;">
    <app-breadcrumbs 
      container="ul" 
      class="GeneralListing--LtoR">
      <li slot-scope="{to, label, utils}">
        <router-link
          :to="to"
          :itemprop="utils && utils.itemprop"
          exact>
          <span>&gt;</span>{{ label }}
        </router-link>
      </li>
      <li
        slot="current"
        slot-scope="{label}">
        <span>&gt;</span>{{ label }}
      </li>
    </app-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  computed: {
    showBreadcrumb() {
      return this.$route.meta.displayBreadCrumb;
    }
  }
};
</script>
