<template>
  <div>
    <section v-show="isLoggedIn && finishedLoading && myReservedVehicle != 3">
      <div class="PriceBlock">
        <h1><span class="fcCalender">Like to View?</span>Select a viewing date below.</h1>
      </div>
      <section class="calendar_block">
        <datepicker
          v-validate="'required'"
          v-model="date"
          :inline="true"
          :format="customFormatter"
          name="date"/>
        <div
          v-show="errors.has('date')"
          class="alert alert-danger">
          {{ errors.first("date") }}
        </div>
      </section>
      <div
        class="form-group">
        <button
          type="button"
          class="btn btn-primary btn--FullWBtn loginbtnright"
          @click="schedule">
          SCHEDULE A TEST DRIVE
        </button>
      </div>
    </section>
  </div>
</template>


<script>
import Datepicker from 'vuejs-datepicker';
import swal from "sweetalert2";
const moment = require('moment');

import { mapGetters } from "vuex";

export default {
  name:"ScheduleTestDrive",
  components: {
    Datepicker
  },
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      date:null,
      finishedLoading: false,
      reservedUserId: null,
      reservedVehicleId: null,
      componentId: 0
    }
  },
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "getAuthUserId"
    ]),
    myReservedVehicle() {
      if(this.reservedUserId === this.getAuthUserId && this.reservedVehicleId === this.vehicle.id) {
        return 1;
      }else if(this.reservedUserId === this.getAuthUserId){
        return 2;
      }else if(this.reservedVehicleId === this.vehicle.id) {
        return 3;
      }else{
        return false;
      }
    }
  },
  watch: {
    vehicle() {
      this.finishedLoading = false;

      if (this.vehicle.id && this.isLoggedIn) {
        this.getReservation(this.vehicle.id).then(() => {
          this.finishedLoading = true;
        });
      }

      if (!this.isLoggedIn) this.finishedLoading = true;
    }
  },
  mounted() {
    this.componentId = this._uid
  },
  methods: {
    async getReservation(vehicle_id) {
      await this.$http
        .get("bankds/staff/buyreservation/reserve/" + vehicle_id)
        .then(response => {
          const response_data = response.data.data;
          this.reservedUserId = response_data.id;
          this.reservedVehicleId = response_data.vehicle_id;
        })
        .catch(error => {
          if (error.response)
            swal("Error", error.response.data.message, "error");
      });
    },
    customFormatter(date) {
      this.date = moment(date).format('YYYY-MM-DD');
    },
    schedule() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let data = {
            vehicle_id: this.vehicle.id,
            vehicle_description: this.vehicle.display_name,
            date: this.date
          }

          this.$http
            .post("bankds/staff/buyreservation/schedule", data)
            .then(() => {

              this.$ga.event({
                eventCategory: 'rental',
                eventAction: 'schedule',
                eventLabel: 'testdrive',
                eventValue: 4
              });

              swal(
                "Sent",
                "A representative will contact you.",
                "success"
              );
            })
            .catch(error => {
              if (error.response.data.status_code === 422) {
                swal("Oops", error.response.data.message, "info");
              } else if (error.response) {
                swal("Error", error.response.data.message, "error");
              }
          });
        }
      });
    }
  }
}
</script>

<style>
.vdp-datepicker__calendar {
  width:100% !important;
}

</style>
