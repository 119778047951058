import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../store/index";

import SiteIndex from "@/components/home/Index.vue";

import Intro from "@/components/intro/Index.vue";
import RentIntro from "@/components/intro/Rent.vue";
import BuyIntro from "@/components/intro/Buy.vue";
import SellIntro from "@/components/intro/Sell.vue";

import RentIndex from "@/components/rent/Index.vue";
import BuyIndex from "@/components/buy/Index.vue";
import SellIndex from "@/components/sell/Index.vue";

import ViewRentVehicle from "@/components/rent/View.vue";
import ViewBuyVehicle from "@/components/buy/View.vue";

import Login from "@/components/authentication/Login.vue";
import Register from "@/components/registration/Register.vue";
import ForgotPassword from "@/components/authentication/Forgot.vue";
import UserProfile from "@/components/user/Profile.vue";
import ResetPassword from "@/components/authentication/ResetPassword.vue";

import Error404 from "@/components/site/Error404.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: [
    { path: "/", redirect: "/index" },
    { path: "/logout", redirect: "/login" },
    {
      path: "/index",
      name: "home",
      component: SiteIndex,
      meta: {
        breadcrumb: "Home",
        displayBreadCrumb: false
      }
    },
    {
      path: "/intro",
      name: "intro",
      redirect: "/intro/rent",
      component: Intro,
      children: [
        {
          path: "rent",
          name: "rentintro",
          component: RentIntro,
          meta: {
            breadcrumb: {
              label: "Rent-A-Car",
              parent: "home"
            },
            displayBreadCrumb: false
          }
        },
        {
          path: "buy",
          name: "buyintro",
          component: BuyIntro,
          meta: {
            breadcrumb: {
              label: "Buy-A-Car",
              parent: "home"
            },
            displayBreadCrumb: false
          }
        },
        {
          path: "sell",
          name: "sellintro",
          component: SellIntro,
          meta: {
            breadcrumb: {
              label: "Sell/Trade-In",
              parent: "home"
            },
            displayBreadCrumb: false
          }
        }
      ]
    },
    {
      path: "/rent",
      name: "rentgrid",
      component: RentIndex,
      meta: {
        breadcrumb: {
          label: "Rent-A-Car",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/buy",
      name: "buygrid",
      component: BuyIndex,
      meta: {
        breadcrumb: {
          label: "Buy-A-Car",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/sell",
      name: "sell",
      component: SellIndex,
      meta: {
        breadcrumb: {
          label: "Sell/Trade-In",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/rent/view/:slug/:vehicle_id",
      name: "rentview",
      component: ViewRentVehicle,
      props: true,
      meta: {
        displayBreadCrumb: true
      }
    },
    {
      path: "/buy/view/:slug/:vehicle_id",
      name: "buyview",
      component: ViewBuyVehicle,
      props: true,
      meta: {
        displayBreadCrumb: true
      }
    },
    {
      path: "/login",
      component: Login,
      meta: {
        breadcrumb: {
          label: "Login",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/register",
      component: Register,
      meta: {
        breadcrumb: {
          label: "Register",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/forgot",
      component: ForgotPassword,
      meta: {
        breadcrumb: {
          label: "Forgot Password",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/password/reset/:token",
      component: ResetPassword,
      meta: {
        breadcrumb: {
          label: "Reset Password",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/profile",
      component: UserProfile,
      meta: {
        requiresAuth: true,
        breadcrumb: {
          label: "My Profile",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/getstarted",
      name: "getstarted",
      component: () => import("../components/elements/GetStarted.vue"),
      meta: {
        breadcrumb: {
          label: "Get Started",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/terms",
      name: "terms",
      component: () => import("../components/site/Terms.vue"),
      meta: {
        breadcrumb: {
          label: "Terms and conditions",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("../components/site/Faq.vue"),
      meta: {
        breadcrumb: {
          label: "Frequently asked questions",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/contactus",
      component: () => import("../components/site/ContactUs.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: {
          label: "Contact us",
          parent: "home"
        },
        displayBreadCrumb: true
      }
    },
    {
      path: "/notfound",
      component: Error404
    },
    {
      path: "*",
      component: Error404
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !store.getters.isLoggedIn) {
    next({
      path: "/login"
    });
  } else {
    next();
  }
});

export default router;
