<template>
  <section class="GeneralPage">
    <div
      v-if="loadingWizard"
      class="loader"/>
    <div
      class="container"
      style="margin-bottom: 48px;">
      <h1 class="title">
        Forgot Password
      </h1>
      <hr>
      <div class="row">
        <div class="col-sm-3">
          <figure>
            <img
              src="@/assets/img/forgot-password.png"
              alt="forgot_logo">
          </figure>
        </div>
        <!-- end col -->
        <div class="col-sm-12 col-md-10 col-lg-8">
          <div class="gForm gForm--WhiBg">
            <p>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
            <div
              :class="errors.has('email')?'has-error':''"
              class="form-group">
              <label for="email">Email address</label>
              <input
                v-validate="'required|email'"
                v-model="email"
                type="email"
                data-vv-as="Email Address"
                name="email"
                class="form-control input-md"
                placeholder="Email Address">
              <span
                v-show="errors.has('email')"
                class="help-block">{{ errors.first('email') }}</span>
            </div>
            <!-- end form-group -->
            <div class="form-group">
              <vue-recaptcha
                ref="recaptcha"
                :sitekey="sitekey"
                @verify="onVerify"
                @expired="onExpired">
                <button
                  :disabled="status==='submitting'"
                  class="btn btn-primary"
                  @click="reset">
                  Reset Password
                </button>
              </vue-recaptcha>
            </div>
            <!-- end form-group -->
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </section>
</template>

<script>
import swal from "sweetalert2";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "ForgotPassword",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      email: "",
      loadingWizard: false,
      sitekey: "6LcC82gUAAAAAD8dTfzPZm4_YEr0RFZbsw8yMyFb",
      verifiedCode: "",
      status: ""
    };
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    recaptchaScript.async = true;
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    onVerify: function(response) {
      const self = this;
      this.setLoading(true);
      self.status = "submitting";
      self.$refs.recaptcha.reset();
      self.status = "";
      this.setLoading(false);
      this.verifiedCode = response;
      this.submitForm();
    },
    onExpired: function() {
      swal("Expired", "The recapcha has expired.", "error");
    },
    reset() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$refs.recaptcha.execute();
        }
      });
    },
    submitForm() {
      var data = {
        emailaddress: this.email,
        site_url: process.env.VUE_APP_DOMAIN,
        verified_captcha_code: this.verifiedCode
      };

      this.setLoading(true);
      this.$http
        .post("bankds/staff/password/email", data)
        .then(response => {
          this.setLoading(false);
          swal("Success", response.data.data.message, "success");
        })
        .catch(error => {
          this.setLoading(false);

          if (error.response)
            swal("Error", error.response.data.message, "error");
      });
    }
  }
};
</script>

<style>
</style>
