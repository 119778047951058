<template>
  <div>
    <footer class="MainFooter mobile_none">
      <div class="container">
        <div class="JamiesBox">
          <picture class="Obj-Fit__Jamie">
            <source 
              media="(min-width: 1399px)" 
              srcset="@/assets/img/girl--dsk.png" >
            <img 
              src="@/assets/img/girlnew.png" 
              alt="Jamie making his bid" >
          </picture>
        </div>
        <!-- /MrPointyBox -->
        <div class="FooterInnerBox">
          <div class="row">
            <div class="col-smlogo-12 col-md-3 col-lg-3">
              <div class="CallUs">
                <img 
                  src="@/assets/img/footerlogo.png" 
                  class="imgresp" 
                  alt="Auction Online logo" >
              </div>
              <!-- /CallUs -->
            </div>
            <!-- /col -->

            <div class="col-xs-5 col-sm-4 col-md-4 col-lg-3 mobile_none">
              <section class="FooterLinkBox">
                <h1>Useful links</h1>
                <ul>
                  <li>
                    <router-link 
                      to="/rent"><span>&gt;</span>&nbsp;&nbsp;Rent-a-car
                    </router-link>
                  </li>
                  <li>
                    <router-link 
                      to="/buy"><span>&gt;</span>&nbsp;&nbsp;Buy-a-car
                    </router-link>
                  </li>
                  <li>
                    <router-link 
                      to="/sell"><span>&gt;</span>&nbsp;&nbsp;Sell/Trade-in a car
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->

            <div class="col-xs-5 col-sm-4 col-md-4 col-lg-3">
              <section class="FooterLinkBox">
                <h1>Help</h1>
                <ul>
                  <li>
                    <router-link 
                      to="/faq#rent">
                      <span>&gt;</span>&nbsp;&nbsp;FAQ Rent-a-car
                    </router-link>
                  </li>
                  <li>
                    <router-link 
                      to="/faq#buy">
                      <span>&gt;</span>&nbsp;&nbsp;FAQ Buy-a-car
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->
            <div class="col-xs-5 col-sm-4 col-md-4 col-lg-3">
              <section class="FooterLinkBox">
                <h1>Account info</h1>
                <ul>
                  <li><router-link 
                    v-show="!isLoggedIn" 
                    to="/login"><span>&gt;</span>&nbsp;&nbsp;Login to My Account</router-link></li>
                  <li><router-link 
                    v-show="isLoggedIn" 
                    to="/contactus"><span>&gt;</span>&nbsp;&nbsp;Contact Us</router-link></li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->
          </div>
          <!-- /row -->
        </div>
        <!-- /FooterInnerBox -->
      </div>
      <!-- /container -->
      <section class="lowerfooter">
        <div class="container">
          <div class="footertext col-lg-8">
            <span style="color:#fff">
              Standard Bank is a licensed financial services provider in terms of the
              Financial Advisory and Intermediary Services Act and a registered credit provider in terms of
              the National Credit Act, registration number NCRCP15
            </span>
          </div>
          <div class="footerlinks">
            <table>
              <tbody>
                <tr>
                  <td class="footlink">
                    <router-link to="/terms#rent">
                      <span style="color:#fff; margin-bottom: 48px">
                        Terms &
                        Conditions
                      </span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /row -->
      </section>
    </footer>

    <footer class="MainFooter desktop_none">
      <div class="container">
        <div class="JamiesBox">
          <picture class="Obj-Fit__Jamie">
            <source 
              media="(min-width: 1399px)" 
              srcset="images/girl--dsk.png" >
            <img 
              src="@/assets/img/girlnew.png" 
              alt="Jamie making his bid" >
          </picture>
        </div>
        <!-- /MrPointyBox -->
        <div class="FooterInnerBox">
          <div class="row">
            <div class="col-smlogo-12 col-md-3 col-lg-3">
              <div class="CallUs">
                <img 
                  src="@/assets/img/footerlogo.png" 
                  class="imgresp" 
                  alt="Auction Online logo" >
              </div>
              <!-- /CallUs -->
            </div>
            <!-- /col -->

            <div class="col-xs-5 col-sm-4 col-md-4 col-lg-3">
              <section class="FooterLinkBox">
                <h1>Useful links</h1>
                <ul>
                  <li>
                    <router-link 
                      to="/rent"><span>&gt;</span>&nbsp;&nbsp;Rent-a-car
                    </router-link>
                  </li>
                  <li>
                    <router-link 
                      to="/buy"><span>&gt;</span>&nbsp;&nbsp;Buy-a-car
                    </router-link>
                  </li>
                  <li>
                    <router-link 
                      to="/sell"><span>&gt;</span>&nbsp;&nbsp;Sell/Trade-in a car
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->

            <div class="col-xs-5 col-sm-4 col-md-4 col-lg-3">
              <section class="FooterLinkBox">
                <h1 class="help_link">
                  Help
                  <i class="fas fa-chevron-down" />
                </h1>
                <ul>
                  <li>
                    <router-link 
                      to="/faq#rent">
                      <span>&gt;</span>&nbsp;&nbsp;FAQ Rent-a-car
                    </router-link>
                  </li>
                  <li>
                    <router-link 
                      to="/faq#buy">
                      <span>&gt;</span>&nbsp;&nbsp;FAQ Buy-a-car
                    </router-link>
                  </li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->
            <div class="col-xs-5 col-sm-4 col-md-4 col-lg-3">
              <section class="FooterLinkBox">
                <h1 class="account_link">
                  Account info
                  <i class="fas fa-chevron-down" />
                </h1>
                <ul>
                  <li><router-link 
                    v-show="!isLoggedIn" 
                    to="/login"><span>&gt;</span> Login to My Account</router-link></li>
                  <li><router-link 
                    v-show="isLoggedIn" 
                    to="/contactus"><span>&gt;</span> Contact Us</router-link></li>
                </ul>
              </section>
              <!-- /FooterLinkBox -->
            </div>
            <!-- /col -->
          </div>
          <!-- /row -->
        </div>
        <!-- /FooterInnerBox -->
      </div>
      <!-- /container -->
      <div class="container">
        <section class="SocialFooter">
          <ul class="d-flex">
            <li>
              <a 
                href="#" 
                title="Join us on FaceBook" 
                target="_blank">
                <i class="fab fa-facebook-square" />
              </a>
            </li>
            <li>
              <a 
                href="#" 
                title="Join us on Twitter" 
                target="_blank">
                <i class="fab fa-twitter-square" />
              </a>
            </li>
            <li>
              <a 
                href="#" 
                title="Follow us on Linkedin" 
                target="_blank">
                <i class="fab fa-instagram" />
              </a>
            </li>
          </ul>
        </section>
      </div>
      <section class="lowerfooter">
        <div class="container">
          <div class="footerlinks">
            <table>
              <tbody>
                <tr>
                  <td class="footlink">
                    <router-link to="/terms#rent">
                      <span style="color:#fff">Terms & Conditions</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /row -->
      </section>
    </footer>
    <cookie-law />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CookieLaw from "vue-cookie-law";

export default {
  name: "SiteFooter",
  components: {
    CookieLaw
  },
  computed: {
    ...mapGetters(["isLoggedIn"])
  }
};
</script>

<style scoped>
.CallUs__Number p a {
  color: #454545;
  font-size: 20px;
}
</style>
