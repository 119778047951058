const SET_TOTAL_VEHICLE_COUNT = "SET_TOTAL_VEHICLE_COUNT";

export default {
  state: {
    totalVehicles: 0
  },
  getters: {
    getTotalVehicles(state) {
      return state.totalVehicles;
    }
  },
  mutations: {
    [SET_TOTAL_VEHICLE_COUNT](state, count) {
      state.totalVehicles = count;
    }
  },
  actions: {
    setTotalVehicleCount({ commit }, count) {
      commit(SET_TOTAL_VEHICLE_COUNT, count);
    }
  }
};
