<template>
  <div class="SFinderBlock">
    <section class="SFinder">
      <form class="gForm gForm--SftBg">
        <h1
          id="h1Toggler"
          aria-expanded="false"
          aria-controls="openRefineSearch"><img
            src="@/assets/img/pinred.png"
            alt="Seacrh icon"> Vehicle Location</h1>
        <div
          id="openRefineSearch"
          class="collapse dont-collapse-sm show">
          <div class="form-group">
            <label
              for="inputModel"
              class="sr-only">Location</label>
            <label class="form-control SlctLightBg searchbox">{{ vehicle.province }}</label>
          </div>
          <h1
            id="h1Toggler"
            data-target="#openRefineSearch"
            aria-expanded="false"
            aria-controls="openRefineSearch"
            class="thumbunderline"><img
              src="@/assets/img/thumbred.png"
              alt="Seacrh icon"> Price Includes:</h1>
          <div>
            <ul>
              <li class="lispan"><span>• Balance of Service</span>
              </li>
              <li class="lispan"><span>• Maintenance plan</span>
              </li>
              <li class="lispan"><span>• Warranty</span>
              </li>
              <li class="lispan"><span>where these were sold with the new vehicle (information available on request)</span></li>
            </ul>
          </div>
          <h1
            id="h1Toggler"
            data-target="#openRefineSearch"
            aria-expanded="false"
            aria-controls="openRefineSearch"
            class="thumbunderline secondhead">Additional Products</h1>
          <div>
            <ol>
              <li
                v-for="(product, index,) in products"
                :key="index"
                class="lispan">
                <router-link
                  :to="viewFaqObject(product.template_ref)"
                  class="dropdown-item">
                  {{ formatProduct(product) }}
                </router-link>
              </li>
            </ol>
          </div>
          <div>
            <ol/>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name:"BuyIncludes",
  props: {
    vehicle: {
      type: Object,
      required: true
    },
    products: {
      type:Array,
      required:true
    }
  },
  methods: {
    formatProduct(product) {
      var mandatory = '';
      var price = '';
      if(product.mandatory) {
        mandatory = ' (Mandatory) '
      }

      if(product.financial_amount > 0) {
        price = ' R'+product.financial_amount
      }
      return product.display_name + mandatory + price
    },

    viewFaqObject(faqSectionLink) {
      return {
        name: 'faq',
        hash: '#buy',
        params: {
          section: '#' + faqSectionLink
        }
      };
    },

    viewTermsObject(termsSectionLink) {
      return {
        name: 'terms',
        hash: '#buy',
        params: {
          section: termsSectionLink
        }
      };
    }
  }
}
</script>

<style scoped>
.lispan a {
  text-decoration:underline;
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 4px 0px !important;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: normal !important;
    background-color: transparent;
    border: 0;
}
</style>
