<template>
  <div>
    <navbar />
    <breadcrumbs />
    <router-view />
    <site-footer />
  </div>
</template>

<script>
import Navbar from "./NavBar";
import Breadcrumbs from "../elements/Breadcrumbs";
import SiteFooter from "./SiteFooter.vue";

export default {
  name: "Layout",
  components: {
    Navbar,
    Breadcrumbs,
    SiteFooter
  }
};
</script>

<style>
</style>
