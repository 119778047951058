<template>
  <section class="TodaysFeaturedVehicles">
    <div class="container">
      <h1 class="d-lg-block">
        Find your vehicle and
        <span style="color:#0089FF;">get moving</span>
      </h1>
      <div class="row">
        <div
          v-for="(randomVehicle, index) in randomVehicles"
          :key="index"
          class="col-sm-6 col-lg-3 car_block">
          <router-link
            :to="viewVehicleObject(randomVehicle)"
            href="#"
            class="LinkBlock">
            <div class="card">
              <div class="Obj-Fit__TodaysFeaturedCar">
                <figure
                  v-lazy:background-image="buildImageObj(randomVehicle)"
                  class="card-img-top layer"/>
                <div class="overlay" />
              </div>
              <!-- /Obj-Fit__TodaysFeaturedCar -->
              <div class="Card-CounterBox d-flex justify-content-between">
                <!-- /Card-StarsBox -->
              </div>
              <!-- /Card-CounterBox -->
              <div class="rent_me">
                <div>{{ buttonName(randomVehicle.disposal_type) }}</div>
              </div>
            </div>
            <!-- /card -->
          </router-link>
        <!-- /LinkBlock -->
        </div>
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </section>
  <!-- /TodaysFeaturedVehicles -->
</template>

<script>
import swal from "sweetalert2"

export default {
  name:'TodaysFeaturedVehicles',
  data() {
    return {
      randomVehicles:[]
    }
  },
  created() {
    this.$http
      .get("bankds/staff/randomvehicles")
      .then(response => {
        this.randomVehicles = response.data.data;
      })
      .catch(error => {
        if (error.response) swal("Error", error.response.data.message, "error");
    });
  },
  methods: {
    buttonName(disposalType) {
      return disposalType == "BUY"?"BUY ME":"RENT ME";
    },
    buildImageObj(randomVehicle) {
      return {
        src: randomVehicle.main_image,
        loading: "https://img.overtake.co.za/global/grid.svg"
      }
    },
    viewVehicleObject(randomVehicle) {
      return {
        name: randomVehicle.disposal_type == "BUY"?"buyview":"rentview",
        params: {
          slug: randomVehicle.slug,
          vehicle_id: randomVehicle.vehicle_id
        }
      }
    }
  },
};
</script>

<style scoped>
.layer[lazy="loading"] {
  background-color: #35404f;
  background-size: 25%;
}
.layer {
  padding-bottom: 100%;
  width: 100%;
}
.layer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.layer {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>
