<template>
  <div v-show="finishedLoading">
    <div
      class="PriceBlock"
      style="margin-bottom: 24px">
      <h1 class="headbuy">Available on:</h1>
      <ul class="textul">
        <li><b>•</b> Cash or</li>
        <li><b>•</b> Finance</li>
      </ul>
      <h1 class="headbuy">Price: {{ vehicle.buy_now_price }}</h1>
    </div>
    <section v-show="isLoggedIn">
      <section v-if="!myReservedVehicle">
        <button
          id="shedview"
          type="submit"
          class="btn btn-primary btn--FullWBtn loginbtnright"
          @click="modalOpen = !modalOpen">BUY ME</button>

        <buy-popup
          :open="modalOpen"
          :vehicle="vehicle"
          :products="products"
          @reserve="vehicleReserved()"
          @modalClosed="modalClosed()"/>
      </section>
      <section
        v-else-if="myReservedVehicle == 1"
        class="PriceBlock">
        <h1>Reserved<span class="fcPrice fcCalender">Congrats!</span></h1>
      </section>
      <section
        v-else-if="myReservedVehicle == 2"
        class="PriceBlock">
        <h1 style="padding-bottom: 24px"><span
          class="fcPrice"
          style="padding-bottom: 24px">Oops!</span>You may only reserve
          one vehicle at a time.</h1>
      </section>
      <section
        v-else-if="myReservedVehicle == 3"
        class="PriceBlock">
        <h1 style="padding-bottom: 24px"><span
          class="fcPrice"
          style="padding-bottom: 24px">Oops!</span>Taken</h1>
      </section>
    </section>
    <section v-show="!isLoggedIn">
      <router-link
        class="btn btn-primary btn--FullWBtn loginbtnright"
        to="/getstarted">
        LOGIN | SIGN UP
      </router-link>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import swal from "sweetalert2"

import BuyPopup from '../elements/BuyModal'

export default {
  name: 'BuyModal',
  components: {
    BuyPopup
  },
  props: {
    vehicle: {
      type:Object,
      required:true
    },
    products: {
      type:Array,
      required:true
    }
  },
  data() {
    return {
      modalOpen: false,
      finishedLoading: false,
      reservedUserId: null,
      reservedVehicleId: null
    }
  },
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "getAuthUserId"
    ]),
    myReservedVehicle() {
      if(this.reservedUserId === this.getAuthUserId && this.reservedVehicleId === this.vehicle.id) {
        return 1;
      }else if(this.reservedUserId === this.getAuthUserId){
        return 2;
      }else if(this.reservedVehicleId === this.vehicle.id) {
        return 3;
      }else{
        return false;
      }
    }
  },
  watch: {
    vehicle() {
      this.finishedLoading = false;

      if (this.vehicle.id && this.isLoggedIn) {
        this.getReservation(this.vehicle.id).then(() => {
          this.finishedLoading = true;
        });
      }

      if (!this.isLoggedIn) this.finishedLoading = true;
    }
  },
  methods: {
    modalClosed() {
      this.modalOpen = false;
    },
    async getReservation(vehicle_id) {
      await this.$http
        .get("bankds/staff/buyreservation/reserve/" + vehicle_id)
        .then(response => {
          const response_data = response.data.data;
          this.reservedUserId = response_data.id;
          this.reservedVehicleId = response_data.vehicle_id;
        })
        .catch(error => {
          if (error.response)
            swal("Error", error.response.data.message, "error");
      });
    },
    vehicleReserved() {
      $(".modalman").modal('toggle');
      this.reservedUserId = this.getAuthUserId;
      this.reservedVehicleId = this.vehicle.id;
    }
  }
}
</script>

<style>

</style>
