<template>
  <div class="SFinderBlock">
    <section class="SFinder">
      <form class="gForm gForm--SftBg">
        <h1
          id="h1Toggler"
          aria-expanded="false"
          aria-controls="openRefineSearch"><img
            src="@/assets/img/pinred.png"
            alt="Seacrh icon"> Vehicle Location</h1>
        <div
          id="openRefineSearch"
          class="collapse dont-collapse-sm show">

          <div class="form-group">
            <label
              for="inputModel"
              class="sr-only">Location</label>
            <label class="form-control SlctLightBg searchbox">{{ vehicle.province }}</label>
          </div>
          <h1
            id="h1Toggler"
            data-toggle="collapse"
            data-target="#openRefineSearch"
            aria-expanded="false"
            aria-controls="openRefineSearch"
            class="thumbunderline"><img
              src="@/assets/img/thumbred.png"
              alt="Seacrh icon"> Rental price includes:</h1>
          <div>
            <ol>
              <li class="lispan">
                <router-link
                  :to="viewTermsObject('#registration_licence')"
                  tag="a"><span>Initial license &amp; registration</span>
                </router-link>
              </li>
              <li class="lispan">
                <router-link
                  :to="viewTermsObject('')"
                  tag="a"><span>Roadworthy</span>
                </router-link>
              </li>
              <li class="lispan">
                <router-link
                  :to="viewTermsObject('#employee_rental_scheme')"
                  tag="a"><span>Comprehensive Insurance</span>
                </router-link>
              </li>
              <li class="lispan">
                <router-link
                  :to="viewTermsObject('#tracking_device')"
                  tag="a"><span>Tracking device</span>
                </router-link>
              </li>
              <li class="lispan">
                <router-link
                  :to="viewFaqObject('')"
                  tag="a"><span>Smash &amp; grab</span>
                </router-link>
              </li>
              <li class="lispan">
                <router-link
                  :to="viewFaqObject('')"
                  tag="a"><span>Roadside assist</span>
                </router-link>
              </li>
              <li class="lispan">
                <router-link
                  :to="viewTermsObject('')"
                  tag="a"><span>Balance of Service, Maintenance plan & Warranty where these were sold with the new vehicle (information available on request)</span>
                </router-link>
              </li>
            </ol>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name:"RentalIncludes",
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },
  methods: {
    viewFaqObject(faqSectionLink) {
      return {
        name: 'faq',
        hash: '#rent',
        params: {
          section: faqSectionLink
        }
      };
    },
    viewTermsObject(termsSectionLink) {
      return {
        name: 'terms',
        hash: '#rent',
        params: {
          section: termsSectionLink
        }
      };
    }
  }
}
</script>

<style scoped>
.lispan a {
  text-decoration:underline;
}
</style>
