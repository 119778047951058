<template>
  <section class="FindYourVechile">
    <div class="container">
      <h1 class="d-none d-lg-block">
        Find your vehicle and get moving.
      </h1>
    </div>
    <!-- /container -->
    <section class="CarSearchBar">
      <div class="container">
        <div
          class="MiniInputBarForm">
          <div class="form-row">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="SEARCH CARS ON AUCTION"
                aria-label="Search cars on auction"
                aria-describedby="Search"
                @keyup.enter="search()">
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary RoundedBtn"
                  type="button"
                  @click="search()">
                  <span class="sr-only">Search</span>
                  <img
                    src="@/assets/img/lookfor-ico.png"
                    alt="Search">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="MultiInputBarForm">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputBrand">Search</label>
              <input
                v-model="query"
                type="text"
                name="search"
                tabindex="1"
                class="form-control"
                placeholder="Car Make or Model"
                @keyup.enter="search()">
            </div>
            <div class="form-group col-md-3">
              <label for="inputMinPrice">{{ minTitle }}</label>
              <select
                id="inputMinPrice"
                v-model="minPrice"
                class="form-control custom-select ">
                <option
                  value=""
                  disabled
                  selected>
                  Min Price
                </option>
                <option
                  v-for="option in options"
                  :key="option.value"
                  :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label for="inputMaxPrice">{{ maxTitle }}</label>
              <select
                id="inputMaxPrice"
                v-model="maxPrice"
                class="form-control custom-select ">
                <option
                  value=""
                  disabled
                  selected>
                  Max Price
                </option>
                <option
                  v-for="option in options"
                  :key="option.value"
                  :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <!-- /form-row -->
          <button
            class="btn btn-primary"
            @click="search()">
            Search
          </button>
          <!-- <input type="submit" class="btn btn-primary" value="Search" @click="search()"> -->
        </div>
      </div>
      <!-- /container -->
    </section>
    <!-- /FullBar -->
  </section>
</template>

<script>
import _ from 'lodash'
import buildUrl from "@/mixins/urlBuilder";

export default {
  name: "Search",
  mixins: [buildUrl],
  props: {
    searchUrl: {
      type: String,
      required: true
    },
    minTitle: {
      type:String,
      default:'Min Price'
    },    
    maxTitle: {
      type:String,
      default:'Max Price'
    },
    options: {
      type:Array,
      default: function() {
        return [
          { text: "R1000", value: 1000 },
          { text: "R2000", value: 2000 },
          { text: "R3000", value: 3000 },
          { text: "R4000", value: 4000 },
          { text: "R5000", value: 5000 },
          { text: "R6000", value: 6000 },
          { text: "R7000", value: 7000 },
          { text: "R8000", value: 8000 },
          { text: "R9000", value: 9000 },
          { text: "R10000", value: 10000 },
          { text: "R11000", value: 11000 },
          { text: "R12000", value: 12000 },
          { text: "R13000", value: 13000 },
          { text: "R14000", value: 14000 },
          { text: "R15000", value: 15000 }
        ]
      }
    }
  },
  data() {
    return {
      query: "",
      minPrice: "",
      maxPrice: ""
    };
  },
  methods: {
    search() {
      let minValue = _.minBy(this.options,'value')
      let maxValue = _.maxBy(this.options,'value')
    
      let searchObject = {
        path: this.searchUrl,
        q: this.query,
        min_price: this.minPrice?this.minPrice:minValue.value,
        max_price: this.maxPrice?this.maxPrice:maxValue.value,
        province: 'ALL'
      };
      this.buildUrl(searchObject);
    }
  }
};
</script>
