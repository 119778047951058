var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"match-heights",rawName:"v-match-heights",value:({
    el: ['.card'],// Array of selectors to fix
    disabled: [ // Array of heights where the plugin will not set the heights
      767, // If a string is provided this will be used as a max bound
      [920, 1200], // If an array is provided it will be used as min-max bounds in that order
    ]
  }),expression:"{\n    el: ['.card'],// Array of selectors to fix\n    disabled: [ // Array of heights where the plugin will not set the heights\n      767, // If a string is provided this will be used as a max bound\n      [920, 1200], // If an array is provided it will be used as min-max bounds in that order\n    ]\n  }"}],staticClass:"col-sm-12 col-md-4 col-lg-3",class:_vm.thumbColSize,staticStyle:{"display":"inline-table"}},[_c('router-link',{staticClass:"LinkBlock",attrs:{"to":_vm.viewVehicleObject}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicle.reserved),expression:"vehicle.reserved"}],staticClass:"reservedimg",attrs:{"src":require("@/assets/img/reserved.png")}}),_c('div',{staticClass:"card",class:_vm.vehicle.reserved?'cardreserved':''},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vehicle.reserved),expression:"vehicle.reserved"}],staticClass:"reserved"}),_c('div',{staticClass:"Obj-Fit__RecentlyAdded"},[_c('figure',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.imgObj),expression:"imgObj",arg:"background-image"}],staticClass:"card-img-top layer"})]),_c('div',{staticClass:"Card-CounterBox d-flex justify-content-between"},[_c('div',{class:_vm.vehicleRating})]),_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-text"},[_vm._v(_vm._s(_vm.price))]),_c('router-link',{staticClass:"card-title",attrs:{"to":_vm.viewVehicleObject,"tag":"h5"}},[_vm._v(" "+_vm._s(_vm.vehicle.display_name)+" ")]),_c('ul',{staticClass:"CarFeatureListing d-flex justify-content-between"},[_c('li',[_vm._v(_vm._s(_vm.vehicle.year))]),_c('li',[_vm._v(_vm._s(_vm.manualOrAuto))]),_c('li',[_vm._v(_vm._s(_vm.vehicle.mileage)+"km")])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }