<template>
  <section>
    <intro/>

    <priced-to-go :title="'buying'"/>

    <search
      :options="options"
      :search-url="'/buy'"
      :min-title="'Minimum Buy Price'"
      :max-title="'Maximum Buy Price'"/>

    <!-- Recently Added Vehicles  -->
    <recently-added vehicle-url="bankds/staff/buyvehicle/latestvehicles"/>

    <get-started-buy />
  </section>
</template>

<script>
import Intro from "./IntroBox"
import PricedToGo from "./PricedToGo"
import Search from "../home/Search";
import RecentlyAdded from "../home/NewVehicles";
import GetStartedBuy from "../elements/GetStartedBuy"

export default {
  name: "BuyIntro",
  components: {
    Intro,
    PricedToGo,
    Search,
    RecentlyAdded,
    GetStartedBuy
  },
  data() {
    return {
      options:[
        { text: "R1000", value: 1000 },
        { text: "R25000", value: 25000 },
        { text: "R50000", value: 50000 },
        { text: "R100000", value: 100000 },
        { text: "R200000", value: 200000 },
        { text: "R300000", value: 300000 },
        { text: "R400000", value: 400000 },
        { text: "R500000", value: 500000 },
        { text: "R600000", value: 600000 },
        { text: "R700000", value: 700000 },
        { text: "R800000", value: 800000 },
        { text: "R900000", value: 900000 },
        { text: "R1000000", value: 1000000 },
        { text: "R1250000", value: 1250000 },
        { text: "R1500000", value: 1500000 }
      ]
    }
  },
}
</script>

<style>

</style>
