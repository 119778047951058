// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "@/packages/router.js";
import helpers from "@/packages/helpers.js";
import Auth from "@/packages/auth.js";
import GlobalMixin from "@/mixins/global.js";
import VeeValidate from "@/packages/validation.js";
import VueLazyload from "vue-lazyload";
import VueMatchHeights from "vue-match-heights";
import AxiosPlugin from "@/packages/axios.js";
import VueBrowserUpdate from "vue-browserupdate";
import Vue2Crumbs from "vue-2-crumbs";
import { store } from "./store/index";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/main.css";
import "@/assets/css/custom.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "picturefill";
import VueAnalytics from 'vue-analytics';

//WebFont import
import WebFont from "webfontloader";
WebFont.load({
  google: {
    families: ["Poppins:200,300,400,500,600,700,800"]
  }
});

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-158259283-1',
  router
});

//Browser update if your browser is too old
Vue.use(VueBrowserUpdate, {
  options: {
    reminder: 0,
    required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
    unsupported: true,
    container: document.body
  }
});

Vue.config.productionTip = false;

//Global Mixin
Vue.use(GlobalMixin);
//General helpers file for common methods
Vue.use(helpers);
//Global validation
Vue.use(VeeValidate, { inject: false });
//Where auth and tokens are stored. This could be moved to the State.
Vue.use(Auth);
//Lazy load images
Vue.use(VueLazyload);

//Used to make the thumbnail boxes all the wsame height
Vue.use(VueMatchHeights, {
  disabled: [768]
});
//Axios plugin which contains Axios and Axios setup
Vue.use(AxiosPlugin);

//Breadcrumbs
Vue.use(Vue2Crumbs);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
