<template>
  <section class="HomePage__HeaderBox">
    <info-bar/>

    <router-view />
  </section>  
</template>

<script>
import InfoBar from "./InfoBarSub"
import Intro from "./IntroBox"
export default {
  components: {
    Intro,
    InfoBar
  }
}
</script>

<style>

</style>