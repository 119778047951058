<template>
  <section>
    <section class="SearchResult">
      <div
        class="container"
        style="margin-bottom: 48px;">
        <div class="SearchResult__Content">
          <side-bar />
          <div class="FindResultBlock">
            <div
              v-if="loadingWizard"
              class="loader"/>
            <div class="FindResultInfo d-sm-flex justify-content-between">
              <div class="order-sm-1">
                <form class="rdppForm">
                  <div class="rdppForm__Select" />
                </form>
                <!-- /rdppForm -->
              </div>
              <div class="order-sm-0">
                <p>{{ currentPageTotal }} of {{ pagination.total }} Search Results</p>
              </div>
            </div>
            <!-- /FindResultInfo -->
            <div class="RecentlyAdded FindResultOutput">
              <div class="row">
                <vehicle
                  v-for="vehicle in vehicles"
                  :key="vehicle.id"
                  :thumb-col-size="'col-lg-6 col-xl-4'"
                  :vehicle="vehicle"
                  :viewurl="dynamicUrl(vehicle)"/>
                  <!-- /col -->
              </div>
              <!-- /row -->
            </div>
            <!-- /FindResultOutput -->

            <div class="FindResultInfo d-md-flex justify-content-between">
              <div class="d-none d-md-block">
                <form class="rdppForm">
                  <div class="rdppForm__Select" />
                </form>
                <!-- /rdppForm -->
              </div>
              <pagination
                :pagination="pagination"
                :offset="3"
                @paginate="paginate()"/>
            </div>
            <!-- /FindResultInfo -->
            <div v-if="noVehicles">
              <div class="title-wrap">
                <h2 class="title">
                  No vehicles found - Please select another province
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <get-started-buy/>
  </section>
  <!-- /FindResultBlock -->
</template>

<script>
import buildUrl from "@/mixins/urlBuilder";
import paginationMixin from "@/mixins/pagination";
import dynamicUrlMixin from "@/mixins/dynamicUrl";

import SideBar from "./SideBar.vue";
import Vehicle from "../vehicle/Vehicle.vue";
import Pagination from "../elements/Pagination.vue";
import GetStartedBuy from "../elements/GetStartedBuy.vue"

export default {
  name: "BuyGrid",
  components: {
    Vehicle,
    Pagination,
    SideBar,
    GetStartedBuy
  },
  mixins: [buildUrl, paginationMixin, dynamicUrlMixin],
  data() {
    return {
      vehiclesUrl: `bankds/staff/buyvehicle/vehicles`
    }
  },
  watch: {
    $route() {
      this.getVehicles(this.vehiclesUrl);
    }
  },
  created() {
    this.getVehicles(this.vehiclesUrl);
  }
};
</script>

<style scoped>
span.error {
  color: #e74c3c;
  font-size: 20px;
  display: flex;
  justify-content: center;
}
</style>
