import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import user from "./modules/user";
import vehicle from "./modules/vehicle";

export const store = new Vuex.Store({
  //strict: process.env.NODE_ENV !== 'production',
  modules: {
    user,
    vehicle
  },
  plugins: [createPersistedState()]
});
