<template>
  <div>
    <intro />
    <!-- /IntroBox -->

    <priced-to-go :title="'renting'"/>

    <search
      :search-url="'/rent'"
      :min-title="'Minimum Rental Price'"
      :max-title="'Maximum Rental Price'"/>
    <!-- /FindYourVechile -->

    <!-- Recently Added Vehicles  -->
    <recently-added vehicle-url="bankds/staff/rentalvehicle/latestvehicles"/>

    <get-started-rent />
  </div>
</template>

<script>
import Intro from "./IntroBox"
import PricedToGo from "./PricedToGo"
import Search from "../home/Search";
import RecentlyAdded from "../home/NewVehicles";
import GetStartedRent from "../elements/GetStartedRent";

export default {
  name: "RentalIntro",
  components: {
    Intro,
    PricedToGo,
    Search,
    RecentlyAdded,
    GetStartedRent
  }
};
</script>

<style>
</style>
