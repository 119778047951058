export default {
  methods: {
    dynamicUrl(vehicle) {
      switch(vehicle.disposal_type) {
      case "BUY":
        return "buyview";
      default:
        return "rentview";
      }
    }
  }
}