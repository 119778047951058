<template>
  <section>
    <intro/>

    <priced-to-go :title="'selling'"/>

    <get-started-rent/>
  </section>
</template>

<script>
import Intro from "./IntroBox"
import PricedToGo from "./PricedToGo"
import GetStartedRent from "../elements/GetStartedRent";

export default {
  name:'SellIntro',
  components: {
    Intro,
    PricedToGo,
    GetStartedRent
  }
}
</script>

<style>

</style>