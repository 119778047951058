<template>
  <!-- start sidebar -->
  <div class="col-sm-3">
    <div class="widget">
      <h2 class="subtitle">
        Account Navigation
      </h2>
      <ul class="list list-unstyled">
        <router-link
          v-if="isLoggedIn"
          to="/profile"
          tag="li">
          My Profile
        </router-link>
      </ul>
    </div><!-- end widget -->
  </div><!-- end col -->
  <!-- end sidebar -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountNav",
  computed: {
    ...mapGetters(["isLoggedIn", "getBidlistCount"])
  }
};
</script>

<style scoped>
ul li {
  cursor: pointer;
}
</style>
