<template>
  <section>
    <section class="DetailsPage">
      <div
        v-if="loadingWizard"
        class="loader"/>
      <div
        class="container"
        style="margin-top: 48px; margin-bottom: 48px;">
        <div class="DetailsPage__Content">
          <!-- Search Block Component -->
          <div class="SFinderBlock">
            <rental-includes :vehicle="vehicle"/>
            <section class="FcBlocklaptop">
              <div class="FcBlock">
                <!-- Reservation Area -->

              </div>
            </section>
          </div>
          <!-- End Search Block Component -->

          <div class="VdBlock">
            <div class="VdBlockDetails">
              <div class="VdSpecs d-flex justify-content-between">
                <!-- VdStars -->
              </div>
              <!-- VdSpecs -->
              <h1>
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
                <span>{{ vehicle.model_type }}</span>
              </h1>
            </div>
            <!-- /VdBlockDetails -->

            <vehicle-carousel :images="images" />

            <section
              v-show="vehicle.accessories"
              class="Vd__Accs">
              <h1>Accessories</h1>
              <p>{{ vehicle.accessories }}</p>
            </section>
            <!-- Vd__Accs -->
            <vehicle-specs
              id="vehicle_specs"
              :attributes="attributes"
              :seller-address="sellerAddress"
              :vehicle="vehicle"
              :seller="seller"
              :stock-take="stockTake"/>
          </div>
          <!-- /VdBlock -->
          <section class="">
            <div class="FcBlock">
              <!-- Reservation Area -->
              <reserve
                :rates="rates"
                :vehicle="vehicle"/>
              <!-- Finance Calculator -->
              <rental-rates :rates="rates"/>

              <schedule-viewing
                v-show="isLoggedIn"
                :vehicle="vehicle"/>
            </div>
          </section>

        <!-- FcBlock -->
        </div>
      <!-- /DetailsPage__Content -->
      </div>
    <!-- /container -->
    </section>
    <!-- /DetailsPage -->
    <get-started/>
  </section>

</template>

<script>
import VehicleCarousel from "../vehicle/carousel/VehicleCarousel";
import RentalRates from "../elements/RentalRates";
import VehicleSpecs from "../vehicle/VehicleSpecs";
import RentalIncludes from "../elements/RentalIncludes";
import Reserve from './Reserve';
import ScheduleViewing from '../elements/ScheduleViewing';
import GetStarted from "../elements/GetStarted"

import { mapGetters } from "vuex";

export default {
  name: "RentalView",
  components: {
    VehicleCarousel,
    RentalRates,
    VehicleSpecs,
    RentalIncludes,
    Reserve,
    ScheduleViewing,
    GetStarted
  },
  data() {
    return {
      vehicle: {},
      images: [],
      rates: [],
      attributes: {},
      sellerAddress: {},
      seller: {},
      stockTake: {},
      loadingWizard: false
    };
  },
  breadcrumb() {
    return {
      label: this.vehicle.display_name,
      parent: {
        name: "rentgrid",
        query: this.$route.params.search
      }
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    imageUrl() {
      return this.vehicle.main_image;
    }
  },
  watch: {
    $route() {
      this.getVehicle(this.$route.params.vehicle_id);
    }
  },
  created() {
    this.getVehicle(this.$route.params.vehicle_id);
    this.reload_bsCollapse();
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.reload_bsCollapse);
    });
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    getVehicle(vehicle_id) {
      this.setLoading(true);
      this.$http
        .get(
          "bankds/staff/rentalvehicle/vehicle/" +
            vehicle_id +
            "?include=rates"
        )
        .then(response => {
          this.vehicle = response.data.data;
          this.images = response.data.data.images;
          this.attributes = response.data.data.attributes;
          this.rates = response.data.data.rates.data;
          this.setLoading(false);
        })
        .catch(() => {
          this.setLoading(false);
          this.$router.push("/notfound");
          return Promise.resolve(true);
      });
    },
    reload_bsCollapse() {
      if ($(window).width() <= 767) {
        $("#h1Toggler").attr({
          "data-toggle": "collapse",
          "data-target": "#openRefineSearch"
        });
      } else {
        $("#h1Toggler").removeAttr("data-toggle data-target");
        $("#openRefineSearch").addClass("show");
      }
    }
  }
};
</script>
