<template>
  <div class="SFinderBlock">
    <a
      href="#"
      class="BackLink"/>
    <section class="SFinder">
      <div class="gForm gForm--SftBg">
        <h1
          id="h1Toggler"
          data-toggle="collapse"
          data-target="#openRefineSearch"
          aria-expanded="false"
          aria-controls="openRefineSearch">
          <img
            src="@/assets/img/magred.png"
            alt="Seacrh icon"> Find my Vehicle
        </h1>
        <div
          id="openRefineSearch"
          class="collapse dont-collapse-sm">
          <div class="FullSearchInput">
            <div class="input-group">
              <input
                v-model="query"
                type="text"
                name="search"
                tabindex="1"
                class="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                @keyup.enter="search()">
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary RoundedBtn"
                  type="button"
                  @click="search()">
                  <span class="sr-only">Search</span>
                  <img
                    src="@/assets/img/lookfor-ico.png"
                    alt="Search"
                    style="width:18px; height:18px;">
                </button>
              </div>
              <!-- /input-group-append -->
            </div>
            <!-- /input-group -->
          </div>
          <!-- /FullSearchInput -->
          <div class="form-group">
            <label
              for="inputMake"
              class="sr-only">Make</label>
            <select2
              v-model="selectedMake"
              :options="makes"
              :settings="{ multiple: true, width:'100%',placeholder: 'Make'}"
              @change="search()"/>
          </div>

          <h1
            id="h1Toggler"
            class=""><img
              src="@/assets/img/pinred.png"
              alt="Seacrh icon"> Vehicle Location</h1>
          <div class="form-group">
            <label
              for="inputProvinces"
              class="sr-only">Provinces</label>
            <select2
              v-model="selectedProvince"
              :options="provinces"
              :settings="{ multiple: false, width:'100%',placeholder: 'Province'}"
              @change="search()"/>
          </div>
          <!-- /widget WidgetController-->
          <div class="widget WidgetController">
            <h6 class="subtitle">
              Vehicle Price
            </h6>
            <div class="WcDailIndicator">
              <div class="WcDailIndicator__TypeOf">
                <div class="input-group">
                  <span class="input-group-addon"><img
                    src="@/assets/img/randico.png"
                    alt="Rand icon"
                    style="width:8px; height:17px;"></span>
                  <input
                    v-model="priceValue[0]"
                    type="text"
                    class="form-control WidgetInput"
                    disabled
                    @keyup="sliderBoxChange()">
                </div>
                <!-- /input-group -->
              </div>
              <!-- /WcDailIndicator__TypeOf -->
              <div class="WcDailIndicator__TypeOf">
                <div class="input-group">
                  <span class="input-group-addon"><img
                    src="@/assets/img/randico.png"
                    alt="Rand icon"
                    style="width:8px; height:17px;"></span>
                  <input
                    v-model="priceValue[1]"
                    type="text"
                    class="form-control WidgetInput"
                    disabled
                    @keyup="sliderBoxChange()">
                </div>
                <!-- /input-group -->
              </div>
              <!-- /WcDailIndicator__TypeOf -->
            </div>
            <!-- /WcDailIndicator -->
            <div class="vue-slider-component vue-slider-horizontal">
              <vue-slider
                ref="priceSlider"
                v-model="priceValue"
                v-bind="priceSliderOptions"
                @drag-end="search()"/>
                <!-- /vue-slider -->
            </div>
            <!-- /vue-slider-component -->
          </div>
          <button
            type="button"
            class="btn btn-primary btn--FullWBtn"
            @click="reset()">
            Reset
          </button>
        </div>
      </div>
    </section>
    <!-- /SFinder -->
  </div>
  <!-- /SFinderBlock -->
</template>

<script>
import { mapGetters } from "vuex";
import buildUrl from "@/mixins/urlBuilder";

import Select2 from "v-select2-component";
import vueSlider from "vue-slider-component";

import qs from "query-string";

export default {
  name: "SideBarFilter",
  components: {
    Select2,
    vueSlider
  },
  mixins: [buildUrl],
  data() {
    return {
      filterDataQuery: "",
      query: "",
      provinces:[],
      makes: [],
      selectedMake: [],
      selectedProvince: '',
      priceValue: [0, 1000000],
      priceSliderOptions: {
        tooltip: "hover",
        clickable: false,
        width: "100%",
        height: 8,
        dotSize: 16,
        interval: 10000,
        min: 0,
        max: 1000000,
        formatter: "R {value}",
        bgStyle: {
          backgroundColor: "#fff",
          boxShadow: "inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)"
        },
        tooltipStyle: {
          backgroundColor: "#666",
          borderColor: "#666"
        },
        processStyle: {
          backgroundColor: "#999"
        }
      }
    };
  },
  computed: {
    ...mapGetters(["getProvince"]),
  },
  created() {
    if(this.getProvince && this.selectedProvince == '') {
      this.selectedProvince = this.getProvince
      this.search();
    }else {
      this.getSelect2Data();
    }

    // eslint-disable-next-line
    let makes = "";
    this.query = this.$route.query.q;
    if (typeof this.$route.query.make !== "undefined") {
      this.selectedMake = this.$route.query.make.split(",");
      makes = this.$route.query.make
        .toUpperCase()
        .split(",")
        .join(", ");
    }
    if (this.$route.query.min_price || this.$route.query.max_price)
      this.priceValue = [
        this.$route.query.min_price,
        this.$route.query.max_price
      ];

    if (this.$route.query.province)
      this.selectedProvince = this.$route.query.province;
  },
  methods: {
    getSelect2Data() {
      this.filterDataQuery =
        "?" + qs.stringify(this.$route.query, { sort: false });

      this.$http
        .get(`bankds/staff/buyvehicle/filterData${this.filterDataQuery}`)
        .then(response => {
          this.makes = response.data.makes;
          this.provinces = response.data.provinces;
        })
        .catch(() => {
          return Promise.resolve(true);
      });
    },
    search() {
      this.$ga.event({
        eventCategory: 'buy',
        eventAction: 'search',
        eventLabel: 'buysearch',
        eventValue: 1
      });

      // eslint-disable-next-line
      let make = "", makes = "";
      if (typeof this.selectedMake === "object") {
        make = this.selectedMake.join(",");
        makes = this.selectedMake.join(", ").toUpperCase();
      }

      let searchObject = {
        page: 1,
        q: this.query,
        make: make,
        province: this.selectedProvince,
        min_price: this.priceValue[0],
        max_price: this.priceValue[1]
      };
      this.buildUrl(searchObject);

      this.getSelect2Data();
    },
    reset() {
      this.query = "";
      this.selectedMake = [];
      this.selectedProvince = "";
      this.priceValue = [0, 1000000];
      this.search();
    }
  }
};
</script>
